import React, { Component } from "react";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../BaseUrlApi/Url";
import loginImage from "../../assets/a6afca1989997f7b42d335b748e35f75@2x.png";
import drBob from "../../assets/bob.svg";
import "./ResetPassword.css";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      email: "",
      token: "",
      password: "",
      c_password: "",
      passwordError: false,
      passwordMatch: "",
    };
  }

  componentDidMount() {
    this.checkToken();
  }

  checkToken = async () => {
    try {
      let token = this.props.match.params.token;
      let getResetToken = await axios.get(`${API_URL}/password/find/${token}`);
      this.setState({
        email: getResetToken.data.email,
        token: getResetToken.data.token,
      });
    } catch(e) {
      if(e.response.message === 'This password reset token is invalid.' || e.response.status === 404) {
        swal({
          title: "Link Expired",
          text: "Link Reset password yang kamu gunakan expired saat ini. Ulangi permintaan ubah password dari Aplikasi!",
          icon: "warning",
          // type: "error",
          closeOnClickOutside: false,
          buttons: "Okey",
          dangerMode: true,
        }).then((move) => {
          if(move) {
            window.open('', '_self', ''); window.close();
          }
        })
      }
    }
    
  };

  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  matchPassword = (value) => {
    return value && value === this.state.password;
  };
  resetPassword = async (e) => {
    e.preventDefault();
    let password = {
      email: this.state.email,
      token: this.state.token,
      password: this.state.password,
      password_confirmation: this.state.c_password,
    };

    await axios
      .post(`${API_URL}/password/reset`, password)
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Password Berhasil Diubah, Silakan Kembali ke Aplikasi Anda",
          icon: "success",
          button: "OK",
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Password Gagal Diubah",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };
  render() {
    return (
      <div className="login-admin-body">
        <img src={loginImage} alt="" className="login-image" />
        <div className="title-wrapper">
          <img src={drBob} alt="" className="bob-style" />
          <span className="login-title">
            dr.Bob's <br />
            Clinic
          </span>
        </div>
        <div className="login-form">
          <ValidationForm
            onSubmit={this.resetPassword.bind(this)}
            ref={this.formRef}
            immediate={this.state.immediate}
            setFocusOnError={this.state.setFocusOnError}
          >
            <p className="login-text">BUAT PASSWORD BARU</p>
            <div className="form-group">
              <TextInput
                name="password"
                id="password"
                type="password"
                placeholder="Password Baru"
                className="form-control admin-input"
                onChange={this.handleChange}
                errorMessage={{ required: "Password is required" }}
                required
              />
            </div>
            <div className="form-group">
              <TextInput
                type="password"
                name="c_password"
                placeholder="Ulangi Password Baru"
                className="form-control admin-input"
                validator={this.matchPassword}
                onChange={this.handleChange}
                errorMessage={{
                  required: "Confirm password is required",
                  validator: "Password does not match",
                }}
                required
              />
            </div>
            <button className="btn btn-primary adm-login-button">RESET</button>
          </ValidationForm>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
