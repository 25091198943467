/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import ICScanner from '../../../../assets/ic-scanner.png';
import axiosSelfCheckin from '../../../../config/axiosSelfCheckin';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import './ScanQRCode.css';
import useCountdown from '@bradgarropy/use-countdown';
import { ModalCheckinIsOver } from '../Modal/CheckinIsOver/CheckinIsOver';
import { ModalFailedCheckin } from '../Modal/FailedCheckin/FailedCheckin';
import useDisclosure from '../../../../hooks/useDisclosure';
import { ModalSuccessCheckin } from '../Modal/SuccessCheckin/SuccessCheckin';
import { Spin, Typography } from 'antd';

const { Title } = Typography;

export const ScanQRCode = ({ isPraktekdDrBob, onBack }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tokenCheckIn, setTokenCheckIn] = useState('');
  const [debounceValueCheckin] = useDebounce(tokenCheckIn, 300);

  const [dataCheckin, setDataCheckin] = useState(null);

  const inputRef = useRef(null);

  const modalCheckinOver = useDisclosure();
  const modalFailedCheckin = useDisclosure();
  const modalSuccessCheckin = useDisclosure();

  const countdown = useCountdown({
    seconds: 15,
    format: 'ss',
    onCompleted: () => modalCheckinOver.onOpen(),
  });

  useOutsideClick(inputRef, () => {
    inputRef?.current?.focus();
  });

  const onChangeTokenCheckIn = (e) => setTokenCheckIn(e.target.value);

  const onCheckinPatient = () => {
    setIsLoading(true);
    countdown.pause();
    const payloadCheckin = {
      checkin_token: tokenCheckIn,
    };
    axiosSelfCheckin
      .post(
        `${
          isPraktekdDrBob
            ? '/self-checkin/praktek-dr-bob'
            : '/self-checkin/pondok-vaksin'
        }`,
        payloadCheckin
      )
      .then((res) => {
        setDataCheckin(res.data?.queue);
        modalSuccessCheckin.onOpen();
      })
      .catch(() => modalFailedCheckin.onOpen())
      .finally(() => {
        setTokenCheckIn('');
        setIsLoading(false);
        // countdown.reset();
      });
  };

  useEffect(() => {
    if (!!tokenCheckIn.length) return onCheckinPatient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValueCheckin]);

  return (
    <>
      {isLoading && (
        <div className="loading-self-checkin-container">
          <div className="loading-self-checkin-content">
            <Spin size="large" />
            <div style={{ marginTop: 30 }} />
            <Title level={4}>Checkin sedang diproses...</Title>
          </div>
        </div>
      )}

      <div className="container-scanner">
        <input
          ref={inputRef}
          className="input-token"
          autoFocus
          disabled={isLoading}
          value={tokenCheckIn}
          placeholder="Token Checkin"
          onChange={onChangeTokenCheckIn}
        />

        <div className="bg-white scanner-img-wrapper">
          <img src={ICScanner} alt="ic-scanner.png" />
        </div>

        <p className="scanner-title">Pindai QR Code</p>
        <p className="scanner-sub-title">
          Silahkan pindai QR code anda pada scanner yang disediakan disamping
        </p>

        <p className="scanner-sub-title">
          Pindai QR code anda dalam{' '}
          <span style={{ fontWeight: 700 }}>{countdown.formatted} Detik</span>
        </p>
      </div>

      {modalCheckinOver.isOpen && (
        <ModalCheckinIsOver
          isOpen={modalCheckinOver.isOpen}
          onBack={onBack}
          onClose={() => {
            countdown.reset();
            modalCheckinOver.onClose();
          }}
        />
      )}

      {modalFailedCheckin.isOpen && (
        <ModalFailedCheckin
          isOpen={modalFailedCheckin.isOpen}
          onBack={onBack}
          onClose={() => {
            countdown.reset();
            modalFailedCheckin.onClose();
          }}
        />
      )}

      {modalSuccessCheckin.isOpen && (
        <ModalSuccessCheckin
          data={dataCheckin}
          isOpen={modalSuccessCheckin.isOpen}
          onClose={() => {
            countdown.reset();
            modalSuccessCheckin.onClose();
            onBack();
          }}
        />
      )}
    </>
  );
};
