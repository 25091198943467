import React, { Component } from 'react';
import AdminSidebar from '../AdminSidebar/AdminSidebar';
import axios from 'axios';
import swal from 'sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import LoadingOverlay from 'react-loading-overlay';
import DatePicker, { registerLocale } from 'react-datepicker';
import id from 'date-fns/locale/id';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { Button, Modal, FormControl, FormLabel, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { API_URL } from '../../BaseUrlApi/Url';
import './QueueManagement.css';
import moment from 'moment';
import 'moment/locale/id';
import { RadioButton } from '../../CustomJS/RadioButton';
import { faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from 'react-bootstrap4-form-validation';
registerLocale('id', id);

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

const { SearchBar } = Search;

function dateFormatter(cell, row, rowIndex, formatExtraData) {
  return moment(cell).format('dddd, DD MMMM YYYY');
}

function queuesFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="queues-buttons">
      <button
        onClick={() => {
          swal({
            title: 'Yakin Ingin Menghapus?',
            icon: 'warning',
            buttons: ['Batal', 'Hapus'],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/queues/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = '/queue-praktek';
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger"
        style={{ margin: '.2em' }}
      >
        Hapus
      </button>
      <Link to={`detail-queue/${row.id}`}>
        <button className="btn btn-primary" style={{ margin: '.2em' }}>
          Detail
        </button>
      </Link>
    </div>
  );
}

function statusFormatter(cell, row, rowIndex, formatExtraData) {
  if (row.queue_status.id === 1) {
    return (
      <button className="registered-style">
        <p>Registered </p>
      </button>
    );
  } else if (row.queue_status.id === 2) {
    return (
      <button className="confirmed-style">
        <p>Confirmed </p>
      </button>
    );
  } else if (row.queue_status.id === 3) {
    return (
      <button className="checkedin-style">
        <p>Checked-in </p>
      </button>
    );
  } else if (row.queue_status.id === 4) {
    return (
      <button className="process-style">
        <p>On-process </p>
      </button>
    );
  } else if (row.queue_status.id === 5) {
    return (
      <button className="done-style">
        <p>Done </p>
      </button>
    );
  } else if (row.queue_status.id === 6) {
    return (
      <button className="passed-style">
        <p>Passed </p>
      </button>
    );
  } else if (row.queue_status.id === 7) {
    return (
      <button className="confirmed-style">
        <p>Canceled </p>
      </button>
    );
  } else if (row.queue_status.id === 8) {
    return (
      <button className="emergency-style">
        <p>Emergency </p>
      </button>
    );
  }
}
function genderFormatter(cell, row, rowIndex, formatExtraData) {
  if (row.child !== null) {
    if (row.child.gender === 'female') {
      return <p>Perempuan</p>;
    } else {
      return <p>Laki-laki</p>;
    }
  } else {
    return <p style={{ color: 'red' }}>Data Anak Terhapus</p>;
  }
}

function shiftFormatter(cell, row) {
  if (cell !== undefined) {
    return cell;
  } else {
    return '-';
  }
}

function onFormatQueueNumber(cell, row) {
  const queueNumber = row?.queue_number ? row?.queue_number : '-';
  return <span>{queueNumber}</span>;
}

class QueueManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      childId: null,
      shiftId: null,
      // startDate: new Date(),
      radioValue: null,
      date_queue: null,
      filterByShiftId: false,
      listShifts: [],
      selectedFilterByShiftId: null,
      name_patient: '',
      showModal: false,
      data: null,
      page: 1,
      search: '',
      isSearched: false,
      checkbox: false,
      products: [],
      choice_queue: '',
      columns: [
        {
          dataField: 'date',
          text: 'Tanggal',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: dateFormatter,
        },
        {
          dataField: 'shift.name',
          text: 'Shift',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: shiftFormatter,
        },
        {
          dataField: 'queue_number',
          text: 'No. Registrasi',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: onFormatQueueNumber,
        },
        {
          dataField: 'child.fullname',
          text: 'Nama',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
        },
        {
          dataField: 'child.age',
          text: 'Umur',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
        },
        {
          dataField: 'queue_status.name',
          text: 'Status',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: statusFormatter,
        },
        {
          dataField: 'child.gender',
          text: 'Jenis Kelamin',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: genderFormatter,
        },
        {
          dataField: 'Aksi',
          text: 'Aksi',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: queuesFormatter,
        },
      ],
    };
  }

  async componentDidMount() {
    const shift_id = localStorage.getItem('shift_id');
    this.setState({ isLoaded: true });
    let getQueues = await axios.get(
      `${API_URL}/admin/queues?type=praktek_offline&period=upcoming&page=1&limit=50`,
      {
        headers,
      }
    );

    // GET SHIFTS FOR SELECTOR
    let getShifts = await axios.get(`${API_URL}/admin/shifts`, { headers });
    let shifts = getShifts.data.shifts.map((shift) => ({
      value: shift.id,
      label:
        shift.name + ' (' + shift.start_time + ' - ' + shift.end_time + ') ',
    }));

    this.setState({
      products: getQueues.data.queues.data,
      data: getQueues.data.queues,
      choice_queue: 'upcoming',
      shiftId: shift_id,
      listShifts: shifts,
    });

    this.setState({ isLoaded: false });
  }

  getAllQueue = async (e) => {
    await this.setState({
      checkbox: !this.state.checkbox,
      isLoaded: true,
    });

    if (this.state.checkbox === true) {
      let getQueues = await axios.get(
        `${API_URL}/admin/queues?type=praktek_offline&period=alltime&page=1&limit=50`,
        {
          headers,
        }
      );
      this.setState({
        products: getQueues.data.queues.data,
        data: getQueues.data.queues,
        page: 1,
        radioValue: null,
        isSearched: false,
        choice_queue: 'alltime',
      });
      this.setState({ isLoaded: false });
    } else {
      let getQueues = await axios.get(
        `${API_URL}/admin/queues?type=praktek_offline&period=upcoming&page=1&limit=50`,
        {
          headers,
        }
      );
      this.setState({
        products: getQueues.data.queues.data,
        page: 1,
        data: getQueues.data.queues,
        isSearched: false,
        choice_queue: 'upcoming',
      });
      this.setState({ isLoaded: false });
    }
  };

  resetQueue = async () => {
    try {
      this.setState({
        // products: getQueues.data.queues.data,
        // data: getQueues.data.queues,
        // page: 1,

        isLoaded: true,
        // isSearched: false,
      });
      let getQueues = await axios.get(
        `${API_URL}/admin/queues?type=praktek_offline&period=upcoming&page=1&limit=50`,
        {
          headers,
        }
      );
      this.setState({
        products: getQueues.data.queues.data,
        data: getQueues.data.queues,
        page: 1,
        radioValue: null,
        choice_queue: 'upcoming',
        checkbox: false,
        isLoaded: false,
        isSearched: false,
      });
    } catch (e) {
      console.log(e.response.data.message);
    }
  };

  searchQueue = async () => {
    // if (this.state.search.length > 0) {
    this.setState({
      showModal: false,
      isLoaded: true,
    });
    if (this.state.radioValue === 1) {
      let getQueues = await axios.get(
        `${API_URL}/admin/queues/search?type=praktek_offline&period=${
          this.state.choice_queue
        }&keyword=${this.state.name_patient}${
          this.state.selectedFilterByShiftId !== null
            ? `&shift_id=${this.state.selectedFilterByShiftId}`
            : ''
        }`,
        { headers }
      );
      await this.setState({
        products: getQueues.data.queues,
        page: 1,
        // choice_queue: 'upcoming',
        checkbox: false,
        // page_count: getParents.data.parents.length / 100,
        isLoaded: false,
        isSearched: true,
      });
    } else {
      let getQueues = await axios.get(
        `${API_URL}/admin/queues/search?type=praktek_offline&period=${
          this.state.choice_queue
        }&keyword=${moment(this.state.date_queue).format('YYYY-MM-DD')}`,
        { headers }
      );
      await this.setState({
        products: getQueues.data.queues,
        page: 1,
        // choice_queue: 'upcoming',
        checkbox: false,
        // page_count: getParents.data.parents.length / 100,
        isLoaded: false,
        isSearched: true,
      });
    }

    // } else {
    //   swal("Gagal!", "Masukkan minimal 3 karakter!", "error");
    // }
  };

  handleNextPage =
    ({ page, onPageChange }) =>
    async () => {
      await this.setState({
        page: this.state.page + 1,
        isLoaded: true,
      });
      let getQueues = await axios.get(
        `${API_URL}/admin/queues?type=praktek_offline&period=${this.state.choice_queue}&page=${this.state.page}&limit=50`,
        { headers }
      );
      await this.setState({
        products: getQueues.data.queues.data,
        data: getQueues.data.queues,
        isLoaded: false,
      });
    };

  handlePrevPage =
    ({ page, onPageChange }) =>
    async () => {
      await this.setState({
        page: this.state.page - 1,
        isLoaded: true,
      });
      let getQueues = await axios.get(
        `${API_URL}/admin/queues?type=praktek_offline&period=${this.state.choice_queue}&page=${this.state.page}&limit=50`,
        { headers }
      );
      await this.setState({
        products: getQueues.data.queues.data,
        data: getQueues.data.queues,
        isLoaded: false,
      });
    };

  // handleNextPageSearch = ({ page, onPageChange }) => () => {
  //   onPageChange(page + 1);
  // };

  // handlePrevPageSearch = ({ page, onPageChange }) => () => {
  //   onPageChange(page - 1);
  // };
  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Showing {from} to {to} of {size} Results
      </span>
    );
    const options = {
      custom: true,
      totalSize: 10,
      sizePerPage: 100,
    };

    const isWeekday = (date) => {
      const day = date.getDay();
      return day !== 0;
    };

    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section">
              <h1 className="patient-page-title">Manajemen Antrian Praktek</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: '#039BEB' }}>
                  Manajemen Antrian Praktek
                </span>
                <span style={{ color: '#00000029' }}> / Daftar Tiket</span>
              </h2>
              <div className="add-button float-right mr-3 mb-2">
                <Link to="/add-family">
                  <button className="btn btn-primary mr-1">
                    Buat Data Keluarga
                  </button>
                </Link>
                <Link to="/queue">
                  <button className="btn btn-primary">Buat Antrian</button>
                </Link>
              </div>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">
                  <div
                    className="demo-section"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '3fr 1fr 1fr',
                      gap: '.5em',
                      marginBottom: '1rem',
                    }}
                  >
                    <div className="checkbox rounded-8">
                      <div className="checkbox-container mt-1">
                        <input
                          id="checkbox-rounded-8"
                          type="checkbox"
                          onChange={this.getAllQueue}
                        />
                        <div className="checkbox-checkmark"></div>
                      </div>
                      <label
                        for="checkbox-rounded-8"
                        style={{ lineHeight: '1em' }}
                      >
                        Tampilkan seluruh antrian (
                        <span className="text-danger">
                          Pengambilan data akan membutuhkan waktu sedikit lebih
                          lama
                        </span>
                        )
                      </label>
                    </div>
                    <div>
                      <Button
                        disabled={!this.state.isSearched}
                        onClick={() => this.resetQueue()}
                        variant="danger"
                      >
                        Reset Pencarian
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.setState({ showModal: true })}
                      >
                        Cari Data
                      </Button>
                    </div>
                  </div>
                  {this.state.isSearched === false ? (
                    <PaginationProvider pagination={paginationFactory(options)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <BootstrapTable
                            keyField="id"
                            bordered={false}
                            data={this.state.products}
                            columns={this.state.columns}
                            {...paginationTableProps}
                          />
                          <div className="float-left ml-5">
                            {this.state.data !== null ? (
                              <p>
                                Data {this.state.data.from} hingga{' '}
                                {this.state.data.to} dari{' '}
                                {this.state.data.total} data
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                          <div
                            className="btn-group float-right mr-5 text-center"
                            role="group"
                          >
                            <button
                              className="btn btn-info mr-1"
                              disabled={this.state.page === 1}
                              onClick={this.handlePrevPage(paginationProps)}
                            >
                              Prev Page
                            </button>
                            <button
                              className="btn btn-info"
                              disabled={this.state.products.length < 1}
                              onClick={this.handleNextPage(paginationProps)}
                            >
                              Next Page
                            </button>
                          </div>
                        </>
                      )}
                    </PaginationProvider>
                  ) : (
                    <PaginationProvider pagination={paginationFactory(options)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <div>
                          <BootstrapTable
                            keyField="id"
                            bordered={false}
                            data={this.state.products}
                            columns={this.state.columns}
                            {...paginationTableProps}
                          />
                          <div className="float-left ml-5">
                            {this.state.products.length > 0 ? (
                              <p>
                                Hasil Pencarian : {this.state.products.length}{' '}
                                data
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                          {/* <div
                            className="btn-group float-right mr-5 text-center"
                            role="group" 
                          >
                            <button
                              className="btn btn-info mr-1"
                              disabled={this.state.page === 1}
                              onClick={this.handlePrevPageSearch(
                                paginationProps
                              )}
                            >
                              Prev Page
                            </button>
                            <button
                              className="btn btn-info"
                              onClick={this.handleNextPageSearch(
                                paginationProps
                              )}
                            >
                              Next Page
                            </button>
                          </div> */}
                        </div>
                      )}
                    </PaginationProvider>
                  )}
                </div>
              </div>
            </div>

            <Modal
              style={{ zIndex: 1100 }}
              size="sm"
              show={this.state.showModal}
              onHide={() => this.setState({ showModal: false })}
              // centered
            >
              <Modal.Body>
                <h4 style={{ textAlign: 'center' }}>Pilih Jenis Pencarian</h4>
                <div style={{ padding: '3em 10em' }}>
                  <div style={{ margin: '0 1em' }}>
                    <FormLabel>Periode Antrian</FormLabel>
                    <FormControl
                      as="select"
                      onChange={(e) =>
                        this.setState({ choice_queue: e.target.value })
                      }
                    >
                      <option>---Pilih Periode Antrian---</option>
                      <option value="upcoming">Antrian Mendatang</option>
                      <option value="alltime">Semua Antrian</option>
                    </FormControl>
                  </div>

                  <div style={{ margin: '1em 1em' }}>
                    <FormLabel>Filter Shift</FormLabel>
                    <FormControl
                      as="select"
                      onChange={(e) =>
                        this.setState({
                          selectedFilterByShiftId: e.target.value,
                        })
                      }
                    >
                      <option>---Pilih Shift---</option>
                      {this.state.listShifts.length > 0 ? (
                        <>
                          {this.state.listShifts?.map((shift) => (
                            <option value={shift.value}>{shift.label}</option>
                          ))}
                        </>
                      ) : (
                        <option disabled>Data tidak ditemukan</option>
                      )}
                    </FormControl>
                  </div>

                  <div style={{ padding: '1em 0' }}>
                    {[1, 2].map((item) => (
                      <RadioButton
                        styling={{ border: 'none' }}
                        key={item}
                        value={item}
                        selected={this.state.radioValue}
                        onChange={() => {
                          if (item === 1) {
                            this.setState({
                              date_queue: null,
                            });
                          } else {
                            this.setState({
                              name_patient: '',
                            });
                          }
                          this.setState({ radioValue: item });
                        }}
                        text={
                          item === 1
                            ? 'Cari berdasarkan Nama'
                            : 'Cari berdasarkan Tanggal Antrian'
                        }
                      />
                    ))}
                  </div>

                  <FormControl
                    style={{
                      display: this.state.radioValue === 1 ? '' : 'none',
                    }}
                    type="text"
                    value={this.state.name_patient}
                    onChange={(e) => {
                      this.setState({
                        name_patient: e.target.value,
                        // date_queue: null
                      });
                    }}
                    placeholder="Masukkan nama Pasien"
                  />
                  {this.state.radioValue === 2 && (
                    <DatePicker
                      selected={this.state.date_queue}
                      className="date-picker-custom"
                      onChange={(e) => {
                        // console.log(moment(e).format('YYYY-MM-DD'))
                        this.setState({
                          date_queue: e,
                          // name_patient: ''
                        });
                      }}
                      // excludeDates={excludeDate}
                      filterDate={isWeekday}
                      locale="id"
                      // minDate={new Date()}
                      // locale="id-ID"
                      dateFormat="EEEE, dd MMMM yyyy"
                      placeholderText="Pilih Tanggal"
                      // required
                    />
                  )}

                  <br />
                  <Button
                    variant="primary"
                    block
                    disabled={this.state.radioValue === null}
                    onClick={() => this.searchQueue()}
                  >
                    Cari Data
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default QueueManagement;
