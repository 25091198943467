import React, { Component } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import swal from "sweetalert";
import productsGenerator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { API_URL } from "../../../../BaseUrlApi/Url";
import "./VaccineTypes.css";
import AdminSidebar from "../../../AdminSidebar/AdminSidebar";

const { SearchBar } = Search;
const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

function vaccineTypesFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="table-buttons">
      <button
        onClick={() => {
          swal({
            title: "Yakin Ingin Menghapus?",
            icon: "warning",
            buttons: ["Batal", "Hapus"],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/vaccine_types/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = "/vaccine-types";
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger"
      >
        Hapus
      </button>
      <Link to={`edit-vaccine-type/${row.id}`}>
        <button className="btn btn-primary">Ubah</button>
      </Link>
    </div>
  );
}

class VaccineTypes extends Component {
  state = {
    isLoaded: false,

    products: [],
    columns: [
      {
        dataField: "name",
        text: "Nama Tipe Vaksin",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
      },
      {
        dataField: "description",
        text: "Deskripsi",
        headerStyle: { textAlign: "center", width: "30rem" },
        style: { textAlign: "center" },
      },
      {
        dataField: "frequency",
        text: "Frekuensi",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
      },
      {
        dataField: "Aksi",
        text: "Aksi",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        formatter: vaccineTypesFormatter,
      },
    ],
  };

  loadData = () => {
    this.setState({
      products: productsGenerator(17),
    });
  };

  async componentDidMount() {
    this.setState({ isLoaded: true });

    let getVaccineTypes = await axios.get(`${API_URL}/admin/vaccine_types`, {
      headers,
    });
    await this.setState({
      products: getVaccineTypes.data.data,
    });
    this.setState({ isLoaded: false });
  }

  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan {from} / {to} dari {size} Hasil
      </span>
    );
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "All",
          value: this.state.products.length,
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <ToolkitProvider
              keyField="id"
              columns={this.state.columns}
              data={this.state.products}
              search
            >
              {(toolkitprops) => (
                <div>
                  <SearchBar
                    {...toolkitprops.searchProps}
                    className="search-content"
                  />
                  <BootstrapTable
                    bordered={false}
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>

        {/* <PaginationListStandalone { ...paginationProps } /> */}
      </div>
    );

    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Manajemen Tipe Vaksin</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Master Data</span>
                <span style={{ color: "#00000029" }}>
                  
                  / Daftar Tipe Vaksin
                </span>
              </h2>
              <div className="add-button">
                <a href="/add-vaccine-type">
                  <button className="btn btn-primary btn-add-table mr-3 mb-2">
                    Tambah Tipe Vaksin
                  </button>
                </a>
              </div>
            </div>
            <PaginationProvider pagination={paginationFactory(options)}>
              {contentTable}
            </PaginationProvider>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default VaccineTypes;
