import React, { Component } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import LoadingOverlay from 'react-loading-overlay';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import { API_URL } from '../../../BaseUrlApi/Url';
import './DetailQueue.css';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

class DetailQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      queueId: null,
      date: '',
      order: null,
      fullname: '',
      age: null,
      gender: '',
      mother: '',
      father: '',
      phone_number: null,
      statuses: [],
      status: {},
      childId: null,
      queue_status: {},
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let queueId = parseInt(this.props.match.params.value);
    let getQueue = await axios.get(`${API_URL}/admin/queues/${queueId}`, {
      headers,
    });
    let getQueueStatuses = await axios.get(`${API_URL}/admin/queue_statuses`, {
      headers,
    });
    let queueStatuses = getQueueStatuses.data.queueStatuses
      .filter((item) => ![9, 10, 11, 12].includes(item.id))
      .map((status) => ({
        value: status.id,
        label: status.name,
      }));
    this.setState({
      queueId: queueId,
      date: getQueue.data.queue_info.date,
      order: getQueue.data.queue_info.registration_number,
      childId: getQueue.data.queue_info.child.id,
      fullname: getQueue.data.queue_info.child.fullname,
      age: getQueue.data.queue_info.child.age,
      gender: getQueue.data.queue_info.child.gender,
      address: getQueue.data.queue_info.child.parents.address,
      mother: getQueue.data.queue_info.child.parents.mother_name,
      father: getQueue.data.queue_info.child.parents.father_name,
      phone_number: getQueue.data.queue_info.child.parents.phone_number,
      queue_status: getQueue.data.queue_info.queue_status,
      statuses: queueStatuses,
    });

    let date = new Date(this.state.date);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_date = dd + '-' + mm + '-' + yyyy;
    await this.setState({
      status: {
        value: this.state.queue_status.id,
        label: this.state.queue_status.name,
      },
      date: set_date,
    });
    this.setState({ isLoaded: false });
  }

  queueStatusHandler = async (value) => {
    this.setState({
      status: {
        value: value.value,
        label: value.label,
      },
    });
  };

  queueUpdateStatus = async () => {
    let update_status = {
      _method: 'PATCH',
      queue_status_id: this.state.status.value,
    };
    this.setState({ isLoaded: true });

    axios
      .post(`${API_URL}/admin/queues/${this.state.queueId}`, update_status, {
        headers,
      })
      .then((res) => {
        this.setState({ isLoaded: false });
        swal({
          title: 'Sukses!',
          text: 'Data Antrian Telah Berhasil Diubah!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/queue-praktek`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Lengkapi semua data terlebih dahulu',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };
  render() {
    let {
      isLoaded,
      date,
      order,
      fullname,
      age,
      gender,
      address,
      father,
      mother,
      phone_number,
      statuses,
      status,
    } = this.state;

    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Detail Antrian</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: '#039BEB' }}>Manajemen Antrian</span>
                <span style={{ color: '#039BEB' }}>/ Daftar Antrian</span>
                <span style={{ color: '#00000029' }}> / Detail Antrian</span>
              </h2>
            </div>
            <div className="card shadow mb-5 text-left pl-3">
              <div className="card-body">
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    gap: '0.5em',
                  }}
                >
                  <div>
                    <h5>Anak</h5>
                    <table cellPadding="10" className="parent-data">
                      <tr>
                        <td>Nama</td>
                        <td>: {fullname}</td>
                      </tr>
                      <tr>
                        <td>Umur</td>
                        <td>: {age !== null ? age : '-'} tahun</td>
                      </tr>
                      <tr>
                        <td>Jenis Kelamin</td>
                        <td>
                          : {gender === 'male' ? 'Laki-laki' : 'Perempuan'}
                        </td>
                      </tr>
                    </table>
                    <br />
                    <h5>Orang Tua</h5>
                    <table cellPadding="10" className="parent-data">
                      <tr>
                        <td>Nama Ayah</td>
                        <td>: {father !== null ? father : '-'}</td>
                      </tr>
                      <tr>
                        <td>Nama Ibu</td>
                        <td>: {mother !== null ? mother : '-'}</td>
                      </tr>
                      <tr>
                        <td>Alamat</td>
                        <td>: {address !== null ? address : '-'}</td>
                      </tr>
                      <tr>
                        <td>No. Telp</td>
                        <td>: {phone_number !== null ? phone_number : '-'}</td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <h5>Waktu</h5>
                    <table cellPadding="10" className="parent-data">
                      <tbody>
                        <tr>
                          <td>Tanggal Antri</td>
                          <td>: {date}</td>
                        </tr>
                        <tr>
                          <td>No. Antrian</td>
                          <td>: {order || '-'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <br />
                <br />
                <hr className="horizontal-line" />
                <br />
                <div className="input-detail-queue">
                  <label htmlFor="" className="detail-queue">
                    Status Antrian :
                  </label>
                  <Select
                    options={statuses}
                    value={status}
                    onChange={(value) => this.queueStatusHandler(value)}
                    className="select-selector"
                  />
                  <div className="patient-buttons">
                    <button
                      className="btn btn-primary"
                      onClick={this.queueUpdateStatus}
                    >
                      Simpan
                    </button>
                    <Link to={`/queue-praktek`}>
                      <button className="btn btn-danger mr-1">Batal</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}
export default DetailQueue;
