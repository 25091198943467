import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import LoginPage from './Components/LoginPage/LoginPage';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import AdminDashboard from './Components/Admin/AdminDashboard/AdminDashboard';
// PATIENT MANAGEMENT
import PatientManagement from './Components/Admin/PatientManagement/PatientManagement/PatientManagement';
import AddFamily from './Components/Admin/PatientManagement/AddFamily/AddFamily';
import UpdateFamily from './Components/Admin/PatientManagement/UpdateFamily/UpdateFamily';
import DetailPatient from './Components/Admin/PatientManagement/DetailPatient/DetailPatient';
import ChangePatientData from './Components/Admin/PatientManagement/ChangePatientData/ChangePatientData';
import AddFamilyMember from './Components/Admin/PatientManagement/AddFamilyMember/AddFamilyMember';
import CheckupHistory from './Components/Admin/PatientManagement/CheckupHistory/CheckupHistory';

import QueueManagement from './Components/Admin/QueueManagement/QueueManagement';
import QueueFrontPage from './Components/Admin/Queues/QueueFrontPage/QueueFrontPage';
import MakeQueue from './Components/Admin/QueueManagement/MakeQueue/MakeQueue';
import DetailQueue from './Components/Admin/QueueManagement/DetailQueue/DetailQueue';
import DetailActiveQueue from './Components/Admin/Queues/DetailActiveQueue/DetailActiveQueue';
import UserManagement from './Components/Admin/UserManagement/UserManagement';
import AddUser from './Components/Admin/UserManagement/AddUser/AddUser';
import UpdateUser from './Components/Admin/UserManagement/UpdateUser/UpdateUser';
import UpcomingSchedule from './Components/Admin/PatientManagement/UpcomingSchedule/UpcomingSchedule';

// VACCINATION SCHEDULE
import VaccinationSchedule from './Components/Admin/VaccinationSchedule/VaccinationSchedule';
import SetSchedule from './Components/Admin/VaccinationSchedule/SetSchedule/SetSchedule';
import UpdateVaccinationSchedule from './Components/Admin/VaccinationSchedule/UpdateVaccination/UpdateVaccination';

// VACCINES
import VaccinList from './Components/Admin/MasterData/VaccineList/VaccineList/VaccineList';
import AddVaccine from './Components/Admin/MasterData/VaccineList/AddVaccine/AddVaccine';
import EditVaccine from './Components/Admin/MasterData/VaccineList/EditVaccine/EditVaccine';

// VACCINE TYPES
import VaccineTypes from './Components/Admin/MasterData/VaccineTypes/VaccineTypesList/VaccineTypes';
import AddVaccineType from './Components/Admin/MasterData/VaccineTypes/AddVaccineType/AddVaccineType';
import EditVaccineType from './Components/Admin/MasterData/VaccineTypes/EditVaccineType/EditVaccineType';

// SCHEDULE
import OpenSchedule from './Components/Admin/MasterData/OpenSchedule/OpenSchedule';
import AddShift from './Components/Admin/MasterData/OpenSchedule/AddShift/AddShift';
import UpdateSchedule from './Components/Admin/MasterData/OpenSchedule/UpdateSchedule/UpdateSchedule';

import QrCode from './Components/Admin/QrCode/QrCode';
import BlockedDates from './Components/Admin/MasterData/BlockedDates/BlockedDates';
import AddDate from './Components/Admin/MasterData/BlockedDates/AddBlockedDates/AddDate';
import UpdateDate from './Components/Admin/MasterData/BlockedDates/UpdateBlockedDates/UpdateDate';

// NOTIFICATION
import Context from './Components/Admin/Notification/Context/Context';
import Broadcast from './Components/Admin/Notification/Broadcast/Broadcast';

// QUEUES
import Queues from './Components/Admin/Queues/Queues';
import Checkup from './Components/Suster/Checkup/Checkup';
import PatientDetail from './Components/Suster/PatientDetail/PatientDetail';
import PatientMeasure from './Components/Suster/PatientMeasure/PatientMeasure';
import Vaccination from './Components/Suster/Vaccination/Vaccination';
import AddQueue from './Components/Admin/Queues/AddQueue/AddQueue';

import PatientNote from './Components/Suster/PatientNote/PatientNote';
// VISIT
import Visits from './Components/Admin/Visit/Visits/Visits';
import CreateVisit from './Components/Admin/Visit/CreateVisit/CreateVisit';
import UpdateVisit from './Components/Admin/Visit/UpdateVisit/UpdateVisit';
import UpdatePatientVisit from './Components/Suster/PatientVisit/UpdateVisit';
import PatientVisit from './Components/Suster/PatientVisit/PatientVisit';
import FrontPage from './Components/Suster/SetSchedule/FrontPage/FrontPage';
import EditVaccinationData from './Components/Suster/Vaccination/EditDataVaccination/EditVaccination';

import ShowQueue from './Components/Admin/Queues/ShowQueue/ShowQueue';
import Notifications from './Components/Admin/Notification/Notifications/Notifications';

import AddUpcomingVaccination from './Components/Suster/UpcomingSchedule/AddUpcomingSchedule/AddVaccination';
import EditUpcomingVaccination from './Components/Suster/UpcomingSchedule/EditUpcomingVaccination';
import AddUpcomingVisit from './Components/Suster/UpcomingSchedule/AddUpcomingSchedule/AddVisit';
import './App.css';
import QueueConsultOnline from './Components/Admin/QueueManagement/QueueConsultOnline';
import MakeQueueConsult from './Components/Admin/QueueManagement/MakeQueueConsult/MakeQueueConsult';
import DetailQueueConsult from './Components/Admin/QueueManagement/DetailQueueConsult/DetailQueueConsult';
import QueueFrontPageConsult from './Components/Admin/Queues/QueueFrontPageConsult/QueueFrontPageConsult';
import QueuesConsult from './Components/Admin/Queues/QueuesConsult';
import DetailActiveQueueConsult from './Components/Admin/Queues/DetailActiveQueue/DetailActiveQueueConsult';
import SimpleReactLightbox from 'simple-react-lightbox';
import BankAccounts from './Components/Admin/MasterData/BankAccounts/BankAccounts';
import AddBankAccount from './Components/Admin/MasterData/BankAccounts/AddBankAccount/AddBankAccount';
import EditBankAccount from './Components/Admin/MasterData/BankAccounts/EditBankAccount/EditBankAccount';
import TermsConditions from './Components/Admin/MasterData/TermsAndConditions/TermsConditions';
import EditTerms from './Components/Admin/MasterData/TermsAndConditions/EditTermsAndCondition/EditTerms';
import RunningQueue from './Components/RunningQueue/RunningQueue';
import { SelfCheckin } from './Components/SelfCheckin/SelfCheckin';
import { RequestCertificate } from './Components/Admin/RequestCertificate/RequestCertificate';
import { DetailRequestCertificate } from './Components/Admin/DetailRequestCertificate/DetailRequestCertificate';
import { DuplicateData } from './Components/Admin/DuplicateData/DuplicateData';

class App extends React.Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

  handleExpToken = () => {
    let token = localStorage.getItem('access_token');
    let base64UrlToken = token.split('.')[1];
    let decodedValueToken = JSON.parse(window.atob(base64UrlToken));
    if (
      decodedValueToken.exp < new Date().getTime() / 1000 ||
      localStorage.getItem('access_token') === null
    ) {
      console.log('token expired');
      // this.props.history.push("/login");
      localStorage.clear();
      window.location.reload();
    } else {
      console.log('token not expired');
    }
  };
  render() {
    if (
      localStorage.getItem('access_token') === null ||
      localStorage.getItem('access_token') === 'undefined'
    ) {
      localStorage.clear();
      this.props.history.push('/login');
    } else {
      this.handleExpToken();
    }
    return (
      <div className="App" id="page-wrap" width="75%">
        <SimpleReactLightbox>
          <BrowserRouter>
            {/* <Route path="/login" component={LoginPage} /> */}
            <Route
              path="/reset-password/token=:token"
              component={ResetPassword}
            />
            {/* ADMIN COMPONENTS */}
            <Route path="/admin-dashboard" component={AdminDashboard} />

            {/* PATIENT MANAGEMENT */}
            <Route path="/patients" component={PatientManagement} />
            <Route path="/add-family" component={AddFamily} />
            <Route path="/update-family/:value" component={UpdateFamily} />
            <Route path="/checkup-history/:value" component={CheckupHistory} />
            <Route
              path="/upcoming-schedule/:value"
              component={UpcomingSchedule}
            />

            <Route path="/detail-patient/:value" component={DetailPatient} />
            <Route
              path="/change-patient-data/:value"
              component={ChangePatientData}
            />
            <Route path="/add-member/:value" component={AddFamilyMember} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/blocked-dates" component={BlockedDates} />
            <Route path="/add-date" component={AddDate} />
            <Route path="/update-date/:value" component={UpdateDate} />

            <Route path="/queue-praktek" component={QueueManagement} />
            <Route path="/queue-consult" component={QueueConsultOnline} />
            <Route path="/queue" component={MakeQueue} />
            <Route path="/create-queue-online" component={MakeQueueConsult} />

            <Route path="/detail-queue/:value" component={DetailQueue} />
            <Route
              path="/detail-queue-online/:value"
              component={DetailQueueConsult}
            />

            <Route path="/users" component={UserManagement} />
            <Route path="/add-user" component={AddUser} />
            <Route path="/update-user/:value" component={UpdateUser} />

            <Route path="/qrcode" component={QrCode} />

            <Route
              path="/vaccination-schedule"
              component={VaccinationSchedule}
            />
            <Route path="/set-schedule" component={SetSchedule} />
            <Route
              path="/update-vaccination-schedule/:value"
              component={UpdateVaccinationSchedule}
            />
            <Route path="/queues/:value" component={Queues} />
            <Route path="/queues-online/:value" component={QueuesConsult} />
            {/* <Route path="/queues-consult/:value" component={QueuesConsult} /> */}
            <Route path="/queues-front/:value" component={QueueFrontPage} />
            <Route
              path="/queues-front-online/:value"
              component={QueueFrontPageConsult}
            />
            <Route
              path="/active-queue/:value/:shiftId"
              component={DetailActiveQueue}
            />
            <Route
              path="/active-queue-consult/:value/:shiftId"
              component={DetailActiveQueueConsult}
            />
            <Route path="/add-queue/:value" component={AddQueue} />

            {/* Terms and Condition */}
            <Route path="/terms-and-conditions" component={TermsConditions} />
            <Route path="/update-terms/:value" component={EditTerms} />
            {/* Bank Accounts */}
            <Route path="/bank-accounts" component={BankAccounts} />
            <Route path="/add-bank-account" component={AddBankAccount} />
            <Route
              path="/update-bank-account/:value"
              component={EditBankAccount}
            />

            {/* VISIT */}
            <Route path="/visits" component={Visits} />
            <Route path="/visit" component={CreateVisit} />
            <Route path="/update-visit/:value" component={UpdateVisit} />

            {/* VACCINES */}
            <Route path="/vaccines" component={VaccinList} />
            <Route path="/add-vaccine" component={AddVaccine} />
            <Route path="/edit-vaccine/:value" component={EditVaccine} />

            {/* VACCINE TYPES */}
            <Route path="/vaccine-types" component={VaccineTypes} />
            <Route path="/add-vaccine-type" component={AddVaccineType} />
            <Route
              path="/edit-vaccine-type/:value"
              component={EditVaccineType}
            />

            <Route path="/shifts" component={OpenSchedule} />
            <Route path="/add-shift" component={AddShift} />

            <Route path="/update-shift/:value" component={UpdateSchedule} />

            {/* NOTIFICATION */}
            <Route path="/context" component={Context} />
            <Route path="/broadcast" component={Broadcast} />

            <Route path="/show-queues/:value" component={ShowQueue} />

            {/* -------------------------------------------------------------- */}

            {/* SUSTER COMPONENTS */}
            <Route path="/checkup" component={Checkup} />
            <Route path="/patient-detail" component={PatientDetail} />
            <Route path="/patient-measure" component={PatientMeasure} />

            <Route path="/vaccination" component={Vaccination} />
            <Route path="/edit-vaccination" component={EditVaccinationData} />

            <Route path="/patient-note" component={PatientNote} />
            <Route
              // path="/patient-visit/:queue/:child/:visit"
              path="/patient-visit"
              component={PatientVisit}
            />
            <Route
              path="/update-patient-visit"
              component={UpdatePatientVisit}
            />

            <Route path="/schedules/:queue/:value" component={FrontPage} />

            <Route
              path="/add-upcoming-vaccination/:child"
              component={AddUpcomingVaccination}
            />
            <Route
              path="/edit-upcoming-vaccination/:queueId/:childId"
              component={EditUpcomingVaccination}
            />
            <Route
              path="/add-upcoming-visit/:child"
              component={AddUpcomingVisit}
            />

            <Route path="/running-queue" component={RunningQueue} />
            <Route path="/self-checkin" component={SelfCheckin} />
            <Route path="/request-certificate" component={RequestCertificate} />
            <Route
              path="/detail-request-certificate/:id"
              component={DetailRequestCertificate}
            />
            <Route path="/duplicate-data" component={DuplicateData} />
          </BrowserRouter>
        </SimpleReactLightbox>
      </div>
    );
  }
}

export default App;
