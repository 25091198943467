import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Input, Row, Table, Typography } from 'antd';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import AdminSidebar from '../AdminSidebar/AdminSidebar';
import './RequestCertificate.css';

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Spacer from '../../Spacer';
import { Column } from './components/TableRequestCertificate/Column';
import { useRequestCertificate } from './hooks/useRequestCertificate';

const { Title } = Typography;

export const RequestCertificate = () => {
  const history = useHistory();
  const { dataRequest, isLoading } = useRequestCertificate();
  const [dataTable, setDataTable] = useState([]);
  const [keyword, setKeyword] = useState('');

  const onClickDetail = (id, childId) =>
    history.push({
      pathname: `/detail-request-certificate/${id}`,
      search: `?child=${childId}`,
    });

  const onSearch = (e) => {
    const { value } = e.target;
    setKeyword(value);

    const tempDataTable =
      [...dataRequest]?.filter((item) => {
        return (
          item?.child?.fullname
            ?.toLowerCase()
            ?.match(value?.toLowerCase()?.trim()) ||
          item?.child?.parents?.mother_name
            ?.toLowerCase()
            ?.match(value?.toLowerCase()?.trim()) ||
          item?.child?.parents?.father_name
            ?.toLowerCase()
            ?.match(value?.toLowerCase()?.trim())
        );
      }) || [];

    setDataTable(tempDataTable);
  };

  useEffect(() => {
    if (!!dataRequest?.length) {
      setDataTable(dataRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, dataRequest]);

  return (
    <div className="admin-overlay">
      <AdminSidebar />
      <LoadingOverlay active={false} spinner text="Memuat data...">
        <div className="content-section">
          <div className="certificate-container">
            <Row justify="space-between" align="middle">
              <Col>
                <div className="certificate-title-wrapper">
                  <Title level={3} style={{ margin: 0 }}>
                    Request Sertifikat
                  </Title>
                  <Spacer height={8} />
                  <Breadcrumb style={{ marginLeft: 10 }}>
                    <Breadcrumb.Item>
                      <a href="/admin-dashboard">Dashboard</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Request Sertifikat</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col>
                <div>
                  <Input
                    size="large"
                    placeholder="Search..."
                    style={{ borderRadius: 12 }}
                    prefix={<SearchOutlined />}
                    value={keyword}
                    onChange={onSearch}
                  />
                </div>
              </Col>
            </Row>

            <Spacer height={24} />
            <Row>
              <Col span={24}>
                <Table
                  dataSource={dataTable}
                  loading={isLoading}
                  columns={Column({
                    onClickDetail: (id, childId) => onClickDetail(id, childId),
                  })}
                  scroll={{ x: '100vw', y: 500 }}
                  pagination={false}
                />
              </Col>
            </Row>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};
