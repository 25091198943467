import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";

import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Sidebar from "../../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../../BaseUrlApi/Url";
import "./AddVaccineType.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class AddVaccineType extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      vaccine_type: "",
      frequency: null,
      description: "",
    };
  }

  inputHandler = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleKeyPress = async (e) => {
    // e.preventDefault()
    if (e.key === "Enter") {
      this.saveVaccineType();
    }
  };

  saveVaccineType = async (e) => {
    const postVaccine = {
      name: this.state.vaccine_type,
      description: this.state.description,
      frequency: parseInt(this.state.frequency),
    };

    axios
      .post(`${API_URL}/admin/vaccine_types`, postVaccine, { headers })
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Tipe Vaksin Baru Telah Berhasil Ditambahkan!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = "/vaccine-types";
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };
  cancelVaccineType = () => {
    window.location.href = "/vaccine-types";
  };

  render() {
    return (
      <div className="patient-section">
        <Sidebar />
        <div className="content-section">
          <div className="title-section mb-5">
            <h1 className="patient-page-title">Tambah Tipe Vaksin</h1>
            <h2 className="patient-page-subtitle">
              <span style={{ color: "#039BEB" }}>Master Data</span>
              <span style={{ color: "#039BEB" }}>/ Daftar Tipe Vaksin</span>
              <span style={{ color: "#00000029" }}> / Tambah Tipe Vaksin</span>
            </h2>
          </div>
          <div className="card shadow mb-4">
            <div className="card-body">
              <ValidationForm
                onSubmit={this.saveVaccineType.bind(this)}
                ref={this.formRef}
                immediate={this.state.immediate}
                setFocusOnError={this.state.setFocusOnError}
              >
                <table cellPadding="10" className="add-table text-left">
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Nama Tipe Vaksin
                        </label>
                        <span>:</span>
                      </td>
                      <td>
                        <TextInput
                          type="text"
                          name="vaccine_type"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                          onKeyPress={this.handleKeyPress}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Deskripsi
                        </label>
                        <span>:</span>
                      </td>
                      <td>
                        <textarea
                          type="text"
                          name="description"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                          onKeyPress={this.handleKeyPress}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Frekuensi
                        </label>
                        <span>:</span>
                      </td>
                      <td>
                        <TextInput
                          type="text"
                          name="frequency"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                          onKeyPress={this.handleKeyPress}
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ValidationForm>
              <div className="patient-buttons">
                <button
                  className="btn btn-primary"
                  id="saveButton"
                  onClick={this.saveVaccineType}
                >
                  Simpan
                </button>
                <button
                  className="btn btn-danger mr-1"
                  onClick={this.cancelVaccineType}
                >
                  Batal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddVaccineType;
