import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from "react-loading-overlay";
import { API_URL } from "../../BaseUrlApi/Url";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import axios from "axios";
import "./AdminDashboard.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      date: null,
      isLoaded: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });

    // GET REKAP DATA
    let getDailyData = await axios.get(`${API_URL}/admin/getDailyVisitor`, {
      headers,
    });

    // CONVERT DATE TYPE TO DD-MM-YYYY
    let date = new Date(getDailyData.data.date);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    let set_date = dd + "-" + mm + "-" + yyyy;
    this.setState({
      data: getDailyData.data,
      date: set_date,
    });
    
    this.setState({ isLoaded: false });
  }
  render() {
    let { data, date } = this.state;
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <h1 className="dashboard-date ml-5">Tanggal Hari Ini : {date}</h1>
            <h2 className="dashboard-date ml-5">
              Total Pasien Hari Ini : {data.total}
            </h2>
            <div className="dashboard-card text-center">
              {date !== null
                ? data.visitorByShift.map((res) => (
                    <div className="morning-patients ml-5">
                      <FontAwesomeIcon icon={faUsers} className="card-icon" />
                      <p className="card-title">
                        Total Pasien {res.name} (Orang)
                      </p>
                      <p className="card-number">{res.count}</p>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default AdminDashboard;
