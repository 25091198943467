import React, { Component } from 'react';
import axios from 'axios';
import { Accordion, Card, Button } from 'react-bootstrap';
import swal from 'sweetalert';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { API_URL } from '../../BaseUrlApi/Url';
import LoadingOverlay from 'react-loading-overlay';
import Navbar from '../Navbar/Navbar';
import './PatientDetail.css';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

class PatientDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tab: '',
      queue: null,
      childId: null,
      queueId: null,
      measurementData: [],
      vaccinationData: [],
      visitData: [],
      upcomingVaccinations: [],
      upcomingVisits: [],
      upcomingOnlineConsultation: [],
      loading_button_danger: false,
      loading_button: false,
    };
  }

  async componentDidMount() {
    const { tab, queueId } = queryString.parse(this.props.location.search);
    this.setState({ isLoaded: true });

    // GET QUEUE DATA
    let getQueue = await axios.get(`${API_URL}/nurse/queues/${queueId}`, {
      headers,
    });

    this.setState({
      tab: tab,
      queueId: queueId,
      queue: getQueue.data.queue_info,
      childId: getQueue.data.queue_info.child_id,
      upcomingOnlineConsultation:
        getQueue?.data?.queue_info.child?.upcoming_online_consultation_queues ||
        [],
    });

    // GET VISITS DATA IN QUEUE IF EXIST
    if (getQueue.data.queue_info.visits.length > 0) {
      this.getVisitData();
    }

    // GET VACCINATION DATA IN QUEUE IF EXIST
    if (getQueue.data.queue_info.vaccinations.length > 0) {
      this.getVaccinationData();
    }

    // GET PHYSICAL MEASUREMENT DATA IN QUEUE IF EXIST
    if (getQueue.data.queue_info.physical_measurements.length > 0) {
      this.setState({
        measurementData: getQueue.data.queue_info.physical_measurements,
      });
    }

    // GET UPCOMING VACCINATION THAT FILTER BY CHILD ID
    let filterVaccineType =
      getQueue.data.queue_info.child.upcoming_vaccinations.map((item) => {
        return {
          ...item,
          vaccine_type: item.vaccine_types
            .map((itemx) => itemx.name)
            .join(', '),
        };
      });
    this.setState({
      upcomingVaccinations: filterVaccineType,
      upcomingVisits: getQueue.data.queue_info.child.upcoming_visits,
    });

    this.setState({ isLoaded: false });
  }

  getVisitData = async (e) => {
    try {
      let getVisitData = await axios.get(
        `${API_URL}/nurse/visits?queue_id=${this.state.queueId}`,
        { headers }
      );
      this.setState({
        visitData: getVisitData.data.visits,
      });
    } catch (e) {
      swal({
        title: 'GAGAL',
        text: 'Gagal Mengambil Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  };

  getVaccinationData = async () => {
    try {
      let getVaccinationData = await axios.get(
        `${API_URL}/nurse/vaccinations?queue_id=${this.state.queueId}`,
        { headers }
      );
      let filterVaccineTypes = getVaccinationData.data.vaccinations.map(
        (item) => {
          return {
            ...item,
            vaccine_type: item.vaccine_types
              .map(
                (itemx) => `${itemx.name} / Periode ke-${itemx.pivot.period}`
              )
              .join(', '),
          };
        }
      );
      this.setState({
        vaccinationData: filterVaccineTypes,
      });
    } catch (e) {
      swal({
        title: 'GAGAL',
        text: 'Gagal Mengambil Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  };

  // TO CONVERT DATE TYPE YYYY-MM-DD TO DD-MMMM-YYYY
  dateChanger(value) {
    let date = new Date(value);
    let dd = date.getDate();
    let mm = date.getMonth() + 1;

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_date = dd + '-' + mm + '-' + yyyy;
    return <span>{set_date}</span>;
  }

  // TO DELETE VACCINATION DATA (IN DATA VAKSINASI ACCORDION)
  deleteVaccination = async (id) => {
    let { queueId, tab } = this.state;
    swal({
      title: 'Yakin Ingin Menghapus?',
      icon: 'warning',
      buttons: ['Batal', 'Hapus'],
      dangerMode: true,
    })
      .then(async (move) => {
        if (move) {
          this.setState({ isLoaded: true });
          await axios
            .delete(`${API_URL}/nurse/vaccinations/${id}`, { headers })
            .then((res) => {
              swal({
                title: 'Sukses!',
                text: 'Vaksinasi Berhasil Dihapus!',
                icon: 'success',
                button: 'OK',
              }).then((move) => {
                if (move) {
                  window.location.href = `/patient-detail?tab=${tab}&queueId=${queueId}`;
                } else {
                  return;
                }
              });
            })
            .catch((err) => {
              swal({
                title: 'GAGAL',
                text: 'Data Gagal Dihapus',
                icon: 'warning',
                type: 'error',
                buttons: 'OK',
              });
            });
        } else {
          return;
        }
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Data Gagal Dihapus',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };

  deleteVisit = async (id) => {
    swal({
      title: 'Yakin Ingin Menghapus?',
      icon: 'warning',
      buttons: ['Batal', 'Hapus'],
      dangerMode: true,
    })
      .then(async (move) => {
        if (move) {
          this.setState({ isLoaded: true });
          await axios
            .delete(`${API_URL}/nurse/visits/${id}`, { headers })
            .then((res) => {
              swal({
                title: 'Sukses!',
                text: 'Kunjungan Berhasil Dihapus!',
                icon: 'success',
                button: 'OK',
              }).then((move) => {
                if (move) {
                  window.location.href = `/patient-detail?tab=${this.state.tab}&queueId=${this.state.queueId}`;
                } else {
                  return;
                }
              });
            })
            .catch((err) => {
              swal({
                title: 'GAGAL',
                text: 'Data Gagal Dihapus',
                icon: 'warning',
                type: 'error',
                buttons: 'OK',
              });
            });
        } else {
          return;
        }
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Data Gagal Dihapus',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };

  // TO END THE CHECKUP
  doneButtonHandler = async () => {
    let data = {
      _method: 'PATCH',
      queue_status_id: 5,
    };
    await axios
      .post(`${API_URL}/nurse/queues/${this.state.queueId}`, data, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Selesai Diperiksa!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/checkup`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Permintaan gagal',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };

  moveProcessHandler = async () => {
    this.setState({ loading_button: true });
    let data = {
      _method: 'PATCH',
      queue_status_id: 4,
    };
    await axios
      .post(`${API_URL}/nurse/queues/${this.state.queueId}`, data, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Pasien Masuk dalam Daftar Diperiksa!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/checkup`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Permintaan gagal',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
        this.setState({ loading_button: false });
      });
  };

  moveDangerHandler = async () => {
    this.setState({ loading_button_danger: true });
    let data = {
      _method: 'PATCH',
      queue_status_id: 8,
    };
    await axios
      .post(`${API_URL}/nurse/queues/${this.state.queueId}`, data, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Pasien Masuk dalam Daftar Darurat!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/checkup`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Permintaan gagal',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
        this.setState({ loading_button_danger: false });
      });
  };

  render() {
    let {
      tab,
      queueId,
      childId,
      isLoaded,
      queue,
      upcomingVaccinations,
      upcomingVisits,
      visitData,
      vaccinationData,
      loading_button,
      loading_button_danger,
      upcomingOnlineConsultation,
    } = this.state;

    if (queue !== null) {
      return (
        <div className="nurse-overlay">
          <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
            <Navbar navtitle="Detail Pasien" url="/checkup" />
            <div className="detail-body">
              <div className="patient-data">
                <Accordion defaultActiveKey="0" className="text-left">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <p className="patient-title">Pasien</p>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <table cellPadding="5" className="patient-table">
                          <tbody>
                            <tr>
                              <td>Nama</td>
                              <td>: {queue.child.fullname}</td>
                            </tr>
                            <tr>
                              <td>Tgl Lahir</td>
                              <td>
                                : {this.dateChanger(queue.child.birthdate)}
                              </td>
                            </tr>
                            <tr>
                              <td>Jenis Kelamin</td>
                              <td>
                                :
                                {queue.child.gender === 'male'
                                  ? 'Laki-laki'
                                  : 'Perempuan'}
                              </td>
                            </tr>
                            <tr>
                              <td>Alamat</td>
                              <td>: {queue.child.parents.address}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion defaultActiveKey="0" className="text-left">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <p className="patient-title">Orang tua</p>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <table cellPadding="5" className="patient-table">
                          <tbody>
                            <tr>
                              <td>Nama Ayah</td>
                              <td>: {queue.child.parents.father_name} </td>
                            </tr>
                            <tr>
                              <td>Nama Ibu</td>
                              <td>: {queue.child.parents.mother_name} </td>
                            </tr>
                            <tr>
                              <td>No. Tlpn</td>
                              <td>: {queue.child.parents.phone_number}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0" className="text-left">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <p className="patient-title">Waktu</p>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <table cellPadding="5" className="patient-table">
                          <tbody>
                            <tr>
                              <td>Kunjungan</td>
                              <td>: {queue.shift.name} </td>
                            </tr>
                            <tr>
                              <td>Tanggal</td>
                              <td>: {this.dateChanger(queue.date)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                {tab === 'onprocess' || tab === 'queue' || tab === 'done' ? (
                  <Accordion defaultActiveKey="0" className="text-left">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                        >
                          <p className="patient-title">Data Pengukuran</p>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <table cellPadding="5" className="patient-table">
                            <tbody>
                              <tr>
                                <td>Tanggal</td>
                                <td>
                                  :
                                  {queue.physical_measurements.length > 0
                                    ? this.dateChanger(
                                        queue.physical_measurements[0]
                                          .date_taken
                                      )
                                    : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td>Berat Badan</td>
                                <td>
                                  :
                                  {queue.physical_measurements.length > 0
                                    ? `${queue.physical_measurements[0].body_weight} kg`
                                    : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td>Tinggi Badan</td>
                                <td>
                                  :
                                  {queue.physical_measurements.length > 0
                                    ? `${queue.physical_measurements[0].body_length} cm`
                                    : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td>Lingkar Kepala</td>
                                <td>
                                  :
                                  {queue.physical_measurements.length > 0
                                    ? `${queue.physical_measurements[0].head_circumference} cm`
                                    : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td>Temperatur</td>
                                <td>
                                  :
                                  {queue.physical_measurements.length > 0
                                    ? `${queue.physical_measurements[0].temperature} celcius`
                                    : '-'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ) : (
                  ''
                )}

                {tab === 'onprocess' ? (
                  <Accordion defaultActiveKey="0" className="text-left">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                        >
                          <p className="patient-title">Data Kunjungan</p>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <table cellPadding="5" className="patient-table">
                            <tbody>
                              <tr>
                                <td>Tanggal</td>
                                <td>
                                  <span className="mr-1">:</span>
                                  {visitData.length > 0
                                    ? this.dateChanger(visitData[0].date_plan)
                                    : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td>Jenis Kunjungan</td>
                                <td>
                                  <span className="mr-1">:</span>
                                  {visitData.length > 0
                                    ? `${visitData[0].visit_type.name}`
                                    : '-'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ) : (
                  ''
                )}

                {tab === 'onprocess' || tab === 'done' ? (
                  <Accordion defaultActiveKey="0" className="text-left">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                        >
                          <p className="patient-title">Data Vaksinasi</p>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {vaccinationData.length > 0
                            ? vaccinationData.map((vaccination) => (
                                <div key={vaccination.id}>
                                  <table
                                    cellPadding="5"
                                    className="patient-table"
                                  >
                                    <tbody>
                                      <tr>
                                        <td>Tanggal</td>
                                        <td>
                                          <span className="mr-1">:</span>
                                          {vaccination.date_taken === null
                                            ? '-'
                                            : this.dateChanger(
                                                vaccination.date_taken
                                              )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Vaksin dan Periode</td>
                                        <td>
                                          <span className="mr-1">:</span>{' '}
                                          {`${vaccination.vaccine_type}`}
                                        </td>
                                      </tr>
                                      {/* <tr>
                                        <td>Merk Vaksin</td>
                                        <td>
                                          :
                                          {vaccination.vaccine !== null
                                            ? vaccination.vaccine.brand_name
                                            : "-"}
                                        </td>
                                      </tr> */}
                                      {/* <tr>
                                        <td>Periode</td>
                                        <td>: {`${vaccination.period}`} </td>
                                      </tr> */}
                                      <tr>
                                        <td>Batch</td>
                                        <td>
                                          :
                                          {vaccination.batch !== null
                                            ? vaccination.batch
                                            : '-'}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Aksi</td>
                                        <td>
                                          :
                                          <Link
                                            to={`/edit-vaccination?tab=${tab}&queueId=${queueId}&childId=${childId}&vaccinationId=${vaccination.id}&status=1`}
                                            className="text-primary mr-1"
                                          >
                                            Edit
                                          </Link>
                                          |
                                          <span
                                            className="text-danger ml-1"
                                            onClick={() =>
                                              this.deleteVaccination(
                                                vaccination.id
                                              )
                                            }
                                          >
                                            Hapus
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <hr />
                                </div>
                              ))
                            : null}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ) : (
                  ''
                )}

                {tab === 'onprocess' || tab === 'queue' || tab === 'done' ? (
                  <div>
                    <Accordion defaultActiveKey="0" className="text-left">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            <p className="patient-title">
                              Jadwal Vaksinasi Mendatang
                            </p>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <table cellPadding="5" className="patient-table">
                              {upcomingVaccinations.length > 0
                                ? upcomingVaccinations.map((vaccination) => {
                                    return (
                                      <tbody key={vaccination.id}>
                                        <tr>
                                          <td>Tanggal</td>
                                          <td>
                                            <span className="mr-1">:</span>
                                            {this.dateChanger(
                                              vaccination.date_plan
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Jenis Vaksinasi</td>
                                          <td>: {vaccination.vaccine_type}</td>
                                        </tr>
                                        <tr>
                                          <td>Aksi</td>
                                          <td>
                                            <span className="mr-1">:</span>
                                            <Link
                                              to={`/edit-vaccination?tab=${tab}&queueId=${queueId}&childId=${childId}&vaccinationId=${vaccination.id}&status=2`}
                                              className="text-primary mr-1"
                                            >
                                              Edit
                                            </Link>
                                            |
                                            <span
                                              className="text-danger ml-1"
                                              onClick={() =>
                                                this.deleteVaccination(
                                                  vaccination.id
                                                )
                                              }
                                            >
                                              Hapus
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })
                                : null}
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion defaultActiveKey="0" className="text-left">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            <p className="patient-title">
                              Jadwal Kunjungan Mendatang
                            </p>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <table
                              cellPadding="5"
                              className="patient-table mb-3"
                            >
                              {upcomingVisits.length > 0
                                ? upcomingVisits.map((visit) => {
                                    return (
                                      <tbody key={visit.id}>
                                        <tr>
                                          <td>Tanggal</td>
                                          <td>
                                            <span className="mr-1">:</span>{' '}
                                            {this.dateChanger(visit.date_plan)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Jenis Kunjungan</td>
                                          <td>: {visit.visit_type.name} </td>
                                        </tr>
                                        {tab === 'onprocess' ? (
                                          <tr>
                                            <td>Aksi</td>
                                            <td>
                                              <span className="mr-1">:</span>
                                              {/* STATUS 2 UNTUK UPDATE JADWAL KUNJUNGAN */}
                                              <Link
                                                to={`update-patient-visit?status=2&queueId=${queue.id}&childId=${queue.child.id}&visitId=${visit.id}`}
                                                className="text-primary mr-1"
                                              >
                                                Edit
                                              </Link>
                                              |
                                              <span
                                                className="text-danger ml-1"
                                                onClick={() =>
                                                  this.deleteVisit(visit.id)
                                                }
                                              >
                                                Hapus
                                              </span>
                                            </td>
                                          </tr>
                                        ) : (
                                          ''
                                        )}
                                      </tbody>
                                    );
                                  })
                                : null}
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion defaultActiveKey="1" className="text-left">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                          >
                            <p className="patient-title">
                              Jadwal Konsultasi Online
                            </p>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <table
                              cellPadding="5"
                              className="patient-table mb-3"
                            >
                              {upcomingOnlineConsultation.length > 0
                                ? upcomingOnlineConsultation.map((visit) => {
                                    return (
                                      <tbody key={visit.id}>
                                        <tr>
                                          <td>Tanggal</td>
                                          <td>
                                            <span className="mr-1">:</span>{' '}
                                            {this.dateChanger(visit.date)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Jenis Kunjungan</td>
                                          <td>: Konsultasi online</td>
                                        </tr>
                                        {tab === 'onprocess' ? (
                                          <tr>
                                            <td>Aksi</td>
                                            <td>
                                              <span className="mr-1">:</span>
                                              {/* STATUS 2 UNTUK UPDATE JADWAL KUNJUNGAN */}
                                              <Link
                                                to={`update-patient-visit?status=2&queueId=${queue.id}&childId=${queue.child.id}&visitId=${visit.id}`}
                                                className="text-primary mr-1"
                                              >
                                                Edit
                                              </Link>
                                              |
                                              <span
                                                className="text-danger ml-1"
                                                onClick={() =>
                                                  this.deleteVisit(visit.id)
                                                }
                                              >
                                                Hapus
                                              </span>
                                            </td>
                                          </tr>
                                        ) : (
                                          ''
                                        )}
                                      </tbody>
                                    );
                                  })
                                : null}
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <hr className="horizontal-line" />
              <div className="patient-data">
                <p className="patient-title">Aksi :</p>

                <br />
                {tab === 'onprocess' ? (
                  <div>
                    <Link
                      to={`/patient-measure?tab=${tab}&queueId=${queue.id}&childId=${queue.child.id}`}
                    >
                      <button className="btn btn-primary patient-button">
                        Ukur Pasien
                      </button>
                    </Link>
                    <Link
                      to={`/vaccination?tab=${tab}&queueId=${queue.id}&childId=${queue.child.id}`}
                    >
                      <button className="btn btn-primary patient-button">
                        Vaksinasi
                      </button>
                    </Link>

                    {queue.visits.length > 0 ? (
                      <Link
                        to={`update-patient-visit?status=1&queueId=${queue.id}&childId=${queue.child.id}&visitId=${queue.visits[0].id}`}
                      >
                        <button className="btn btn-primary patient-button">
                          Kunjungan
                        </button>
                      </Link>
                    ) : (
                      <Link
                        to={`patient-visit?queueId=${queue.id}&childId=${queue.child.id}`}
                      >
                        <button className="btn btn-primary patient-button">
                          Kunjungan
                        </button>
                      </Link>
                    )}

                    <Link
                      to={`/patient-note?queueId=${queue.id}&childId=${queue.child.id}`}
                    >
                      <button className="btn btn-primary patient-button">
                        Catatan Anak
                      </button>
                    </Link>
                    <Link to={`/schedules/${queue.id}/${queue.child.id}`}>
                      <button className="btn btn-primary patient-button">
                        Atur Jadwal
                      </button>
                    </Link>
                    <br />
                    <button
                      className="btn btn-primary patient-button-done"
                      onClick={this.doneButtonHandler}
                    >
                      Selesai Periksa
                    </button>
                  </div>
                ) : (
                  ''
                )}

                {tab === 'queue' ? (
                  <div>
                    <Link
                      to={`/patient-measure?tab=${tab}&queueId=${queue.id}&childId=${queue.child.id}`}
                    >
                      <button className="btn btn-primary patient-button">
                        Ukur Pasien
                      </button>
                    </Link>
                    <button
                      className="btn btn-danger patient-button-danger"
                      onClick={this.moveDangerHandler}
                    >
                      {loading_button_danger ? '..Loading' : 'Darurat'}
                    </button>
                    <button
                      className="btn btn-primary patient-button-done"
                      onClick={this.moveProcessHandler}
                    >
                      {loading_button ? '..Loading' : 'Periksa'}
                    </button>
                  </div>
                ) : (
                  ''
                )}

                {tab === 'done' ? (
                  <div>
                    {' '}
                    <Link
                      to={`/patient-measure?tab=${tab}&queueId=${queue.id}&childId=${queue.child.id}`}
                    >
                      <button className="btn btn-primary patient-button">
                        Ukur Pasien
                      </button>
                    </Link>
                    <Link to={`/schedules/${queue.id}/${queue.child.id}`}>
                      <button className="btn btn-primary patient-button">
                        Atur Jadwal
                      </button>
                    </Link>
                    <Link
                      to={`/vaccination?tab=done&queueId=${queue.id}&childId=${queue.child.id}`}
                    >
                      <button className="btn btn-primary patient-button">
                        Vaksinasi
                      </button>
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </LoadingOverlay>
        </div>
      );
    } else {
      return (
        <div>
          <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
            <Navbar navtitle="Detail Pasien" url="/checkup" />
            <div className="detail-body" style={{ height: '100vh' }}></div>
          </LoadingOverlay>
        </div>
      );
    }
  }
}

export default PatientDetail;
