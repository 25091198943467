import { Table, Typography } from 'antd';
import React from 'react';
import { Column } from './Column';
import ICEmptyData from '../../../../../assets/ic-table-empty.png';

const { Title } = Typography;

export const TableDuplicateData = ({
  dataSource,
  onShowModalDetail,
  isLoading,
  isDetail,
  rowSelection,
  pagination,
}) => {
  const tempData = dataSource?.map((item, index) => ({
    ...item,
    key: index + 1,
    is_not_expand: 'Not Expandable',
  }));

  return (
    <Table
      dataSource={tempData}
      rowSelection={{ ...rowSelection }}
      expandable={{
        rowExpandable: (record) => record.is_not_expand !== 'Not Expandable',
        showExpandColumn: false,
      }}
      rowKey="id"
      locale={{
        emptyText: (
          <div>
            <img
              src={ICEmptyData}
              style={{ scale: '0.8' }}
              alt="ic-table-empty.png"
            />
            <Title level={5}>Tidak ada data duplikat.</Title>
          </div>
        ),
      }}
      loading={isLoading}
      columns={Column({
        onClickDetail: (id) => onShowModalDetail(id),
        isDetail,
      })}
      scroll={{ x: !isDetail ? '100vw' : '', y: !isDetail ? 420 : 200 }}
      pagination={false}
    />
  );
};
