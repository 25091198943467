import React, { Component } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { BounceLoader } from 'react-spinners';
import swal from 'sweetalert';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import EmergencyImage from '../../../../assets/alarm.svg';
import { API_URL } from '../../../BaseUrlApi/Url';
import './ShowQueue.css';

const { SearchBar } = Search;

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

class Queues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonEmergency: true,
      loading: true,
      check: '',
      queue_patient_name: null,
      next_queue_patient_name: null,
      process: false,
      queue_process_id: null,
      childId: null,
      shiftId: null,
      orders: null,
      order: 0,
      old_order: null,
      limit: null,
      shift_name: null,
      next_order: 0,
      emergencyQueue: [],
      total_checkin: [],
      emergency: false,
      onProcess: [],
      doctor_arrival_status: 0,
      button: (
        <div>
          <button
            className="btn btn-danger btn-come1"
            onClick={this.passPatient}
          >
            Tidak Datang
          </button>
          <button
            className="btn btn-primary btn-come2"
            onClick={this.checkPatient}
          >
            Periksa
          </button>
        </div>
      ),
      date: new Date(),
      products: [],
      columns: [
        {
          dataField: 'order',
          text: 'No. Antri',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: this.orderFormatter,
        },
        {
          dataField: 'child.fullname',
          text: 'Nama Anak',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
        },
        {
          dataField: 'queue_status.name',
          text: 'Status',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: this.statusFormatter,
        },
      ],
    };
  }

  async componentDidMount() {
    this.getActiveQueues();
  }

  statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.queue_status.id === 1) {
      return (
        <button className="registered-style">
          <p>Registered </p>
        </button>
      );
    } else if (row.queue_status.id === 2) {
      return (
        <button className="confirmed-style">
          <p>Confirmed </p>
        </button>
      );
    } else if (row.queue_status.id === 3) {
      return (
        <button className="confirmed-style">
          <p>Checked-in </p>
        </button>
      );
    } else if (row.queue_status.id === 4) {
      return (
        <button className="process-style">
          <p>On-process </p>
        </button>
      );
    } else if (row.queue_status.id === 5) {
      return (
        <button className="done-style">
          <p>Done </p>
        </button>
      );
    } else if (row.queue_status.id === 6) {
      return (
        <button className="passed-style">
          <p>Passed </p>
        </button>
      );
    } else if (row.queue_status.id === 7) {
      return (
        <button className="confirmed-style">
          <p>Canceled </p>
        </button>
      );
    } else if (row.queue_status.id === 8) {
      return (
        <button className="emergency-style">
          <p>Emergency </p>
        </button>
      );
    }
  };

  orderFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {this.state.emergency && row.queue_status.id === 8 ? (
          <div>
            <img src={EmergencyImage} alt="" className="order-emergency" />
            <span>{row?.queue_number || '-'}</span>
          </div>
        ) : (
          <p>{row?.queue_number || '-'}</p>
        )}
      </div>
    );
  };

  onCheckPatientName = (data, queueNumber) => {
    const filterData =
      data?.queue?.filter((item) => item?.queue_number === queueNumber)?.[0] ||
      null;
    return filterData?.child?.fullname || null;
  };

  getActiveQueues = async () => {
    let isCompeleted = true;
    setInterval(async () => {
      if (isCompeleted) {
        isCompeleted = false;
        let getShiftInfo = await axios.get(
          `${API_URL}/admin/queues/getTodaysQueueInfo`,
          { headers }
        );
        if (
          getShiftInfo.data.message !== 'Tidak ada shift yang sedang berjalan'
        ) {
          this.setState({
            time: Date.now(),
            shiftId: getShiftInfo.data.shift.id,
            shift_name: getShiftInfo.data.shift.name,
            limit: getShiftInfo.data.shift.registration_limit,
            doctor_arrival_status:
              getShiftInfo.data.shift.doctor_arrival_status,
          });

          let getQueues = await axios
            .get(`${API_URL}/admin/queues/getAllActiveQueue`, { headers })
            .finally(() => (isCompeleted = true));
          if (getQueues.data.queue.length > 0) {
            let getCheckinQueues = getQueues.data.queue.filter(
              (order) => order.queue_status.id === 3
            );

            this.setState({
              products: getQueues.data.queue,
              orders: getCheckinQueues.length,
              total_checkin: getCheckinQueues,
              order: getQueues?.data?.current_queue || 0,
              next_order: getQueues?.data?.next_queue || 0,
              queue_patient_name: getQueues?.data?.current_queue
                ? this.onCheckPatientName(
                    getQueues.data,
                    getQueues?.data?.current_queue
                  )
                : null,
              next_queue_patient_name: getQueues?.data?.current_queue
                ? this.onCheckPatientName(
                    getQueues.data,
                    getQueues?.data?.next_queue
                  )
                : null,

              button: (
                <div>
                  <button
                    className="btn btn-danger btn-come1"
                    onClick={this.passPatient}
                  >
                    Tidak Datang
                  </button>
                  <button
                    className="btn btn-primary btn-come2"
                    onClick={this.checkPatient}
                  >
                    Periksa
                  </button>
                </div>
              ),
            });

            // ON PROCESS QUEUES
            let getProcessQueue = getQueues.data.queue.filter(
              (order) => order.queue_status.id === 4
            );

            // if (
            //   this.state.total_checkin.length === 0 && //IF THERE'S NO CHECK IN  AND PROCESS QUEUE (ORDER = 0 AND NEXT_ORDER = 0)
            //   getProcessQueue.length === 0
            // ) {
            //   this.setState({
            //     order: 0,
            //     queue_patient_name: null,
            //     next_order: 0,
            //     next_queue_patient_name: null,
            //   });
            // } else if (
            //   this.state.total_checkin.length === 1 && //IF THERE'S ONE CHECK IN QUEUE AND NO PROCESS QUEUE THEN ORDER = CHECKIN[0] AND NEXT_ORDER = 0
            //   getProcessQueue.length === 0
            // ) {
            //   this.setState({
            //     // order: this.state.total_checkin[0].order,
            //     queue_patient_name: this.state.total_checkin[0].child.fullname,
            //     // next_order: 0,
            //     next_queue_patient_name: null,
            //   });
            // } else if (
            //   this.state.total_checkin.length > 1 && //IF THERE ARE MORE THAN ONE CHECK IN QUEUE BUT PROCESS QUEUE DOESN'T EXIST THEN....
            //   getProcessQueue.length === 0
            // ) {
            //   this.setState({
            //     // order: this.state.total_checkin[0].order,
            //     queue_patient_name: this.state.total_checkin[0].child.fullname,
            //     // next_order: this.state.total_checkin[1].order,
            //     next_queue_patient_name:
            //       this.state.total_checkin[1].child.fullname,
            //   });
            // } else if (
            //   this.state.total_checkin.length === 0 && // IF THERE'S NO CHECK IN BUT PROCESS EXIST THEN...
            //   getProcessQueue.length > 0
            // ) {
            //   this.setState({
            //     // order: getProcessQueue[0].order,
            //     queue_patient_name: getProcessQueue[0].child.fullname,
            //     // next_order: 0,
            //     next_queue_patient_name: null,
            //   });
            // } else if (
            //   this.state.total_checkin.length > 0 && // IF THERE'S CHECK IN AND PROCESS QUEUE THEN ...
            //   getProcessQueue.length > 0
            // ) {
            //   this.setState({
            //     // order: getProcessQueue[0].order,
            //     queue_patient_name: getProcessQueue[0].child.fullname,
            //     // next_order: this.state.total_checkin[0].order,
            //     next_queue_patient_name:
            //       this.state.total_checkin[0].child.fullname,
            //   });
            // }

            if (getProcessQueue.length > 0) {
              await this.setState({
                button: (
                  <button
                    className="btn btn-secondary btn-come2"
                    onClick={this.checkPatient}
                  >
                    Sedang diperiksa
                  </button>
                ),
              });
            }

            // CHECK EMERGENCY QUEUE IF EXIST
            let getEmergency = getQueues.data.queue.filter(
              (order) => order.queue_status.id === 8
            );

            // IF EMERGENCY QUEUE EXIST
            if (getEmergency.length === 1) {
              this.setState({
                // order: getEmergency[0].order,
                // queue_patient_name: getEmergency[0].child.fullname,
                emergency: true,
                emergencyQueue: getEmergency,
                button: (
                  <button className="btn btn-danger btn-come2">DARURAT</button>
                ),
              });

              // if (this.state.total_checkin.length > 0) {
              //   this.setState({
              //     // next_order: this.state.total_checkin[0].order,
              //     next_queue_patient_name:
              //       this.state.total_checkin[0].child.fullname,
              //   });
              // }
            } else if (getEmergency.length > 1) {
              // let next_order = getEmergency.length - 1;
              this.setState({
                // order: getEmergency[next_order].order,
                // queue_patient_name: getEmergency[next_order].child.fullname,
                button: (
                  <button className="btn btn-danger btn-come2">DARURAT</button>
                ),
              });

              // if (this.state.total_checkin.length > 0) {
              //   this.setState({
              //     // next_order: this.state.total_checkin[0].order,
              //     // next_queue_patient_name:this.state.total_checkin[0].child.fullname,
              //   });
              // }
            } else if (getEmergency.length === 0) {
              this.setState({
                emergency: false,
              });
            }
          }
        } else {
          window.location.href = `/queues-front/show-patients`;
        }
      }
    }, 10000);
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date(),
    });
  }

  checkPatient = async (e) => {
    e.preventDefault();
    let queue = this.state.total_checkin;
    if (queue.length > 0) {
      let queueId = parseInt(queue[0].id);
      let updateStatus = {
        _method: 'PATCH',
        queue_status_id: 4,
      };
      await axios
        .post(`${API_URL}/admin/queues/${queueId}`, updateStatus, { headers })
        .then((res) => {
          this.setState({
            button: (
              <button
                className="btn btn-secondary btn-come2"
                onClick={this.checkPatient}
              >
                Sedang diperiksa
              </button>
            ),
          });
        })
        .catch((err) => {});
    } else {
      swal({
        title: 'GAGAL',
        text: 'Tidak Ada Antrian Selanjutnya',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  };

  passPatient = async (e) => {
    e.preventDefault();
    let queue = this.state.total_checkin;
    if (queue.length > 0) {
      let queueId = parseInt(queue[0].id);
      let updateStatus = {
        _method: 'PATCH',
        queue_status_id: 6,
      };
      await axios
        .post(`${API_URL}/admin/queues/${queueId}`, updateStatus, { headers })
        .then((res) => {
          return;
        })
        .catch((err) => {});
    } else {
      swal({
        title: 'GAGAL',
        text: 'Tidak Ada Antrian Selanjutnya',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  };

  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan {from} / {to} dari {size} Hasil
      </span>
    );
    const override = {
      display: 'block',
      margin: '20% auto',
      borderColor: 'red',
    };
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPage: 5,
      sizePerPageList: [
        {
          text: '5',
          value: 5,
        },
      ],
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="mt-5">
        <ToolkitProvider
          keyField="id"
          columns={this.state.columns}
          data={this.state.products}
          search
        >
          {(toolkitprops) => {
            return (
              <div>
                <SearchBar
                  {...toolkitprops.searchProps}
                  className="search-content"
                />
                <BootstrapTable
                  bordered={false}
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                />
              </div>
            );
          }}
        </ToolkitProvider>
      </div>
    );

    let {
      shift_name,
      queue_patient_name,
      next_queue_patient_name,
      doctor_arrival_status,
      order,
      next_order,
      emergency,
      emergencyQueue,
    } = this.state;
    if (shift_name !== null) {
      return (
        <div className="show-card-queue">
          <div className="card shadow mb-4 ml-2 mt-3">
            <div className="card-body ">
              <h3 className="mt-2 ml-5 mb-4 text-left">
                <span className="ml-4">STATUS KEDATANGAN DOKTER :</span>
                <span
                  className="text-info ml-1"
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                  }}
                >
                  {doctor_arrival_status === 0 ? (
                    <span className="text-danger">BELUM DI KLINIK</span>
                  ) : (
                    <span className="text-success">SUDAH DI KLINIK</span>
                  )}
                </span>
              </h3>
              <div className="card-queue float-left">
                <div class="card">
                  <div class="card-header">
                    {emergencyQueue.length > 0 && emergency ? (
                      <img
                        src={EmergencyImage}
                        alt="EMERGENCY"
                        className="emergency-queue"
                      />
                    ) : (
                      ''
                    )}
                    Antrian Sekarang
                    {emergencyQueue.length > 0 && emergency ? (
                      <img
                        src={EmergencyImage}
                        alt="EMERGENCY"
                        className="emergency-queue2"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="card-text">
                    <p className="card-number">
                      {emergencyQueue.length > 0 && emergency ? (
                        <span style={{ color: '#CE2828' }} className="number">
                          {order || '0'}
                        </span>
                      ) : (
                        <span style={{ color: 'black' }} className="number">
                          {order || '0'}
                        </span>
                      )}
                    </p>
                    <span className="card-patient">
                      {queue_patient_name !== null
                        ? `${queue_patient_name}`
                        : ''}
                    </span>
                  </div>
                </div>
                <br />
                <div class="card card-queue2">
                  <div class="card-header">Antrian Selanjutnya</div>
                  <div className="card-text">
                    <p className="card-number">{next_order || '0'}</p>
                    <span className="card-patient">
                      {next_queue_patient_name !== null
                        ? next_queue_patient_name
                        : ''}
                    </span>
                  </div>
                </div>
              </div>
              <div className="queue-table w-50 float-right">
                <h3 className="queue-table-title">
                  Daftar Antrian
                  {this.state.products.length > 0 ? (
                    ''
                  ) : (
                    <span className="warning-text"> (Tidak ada Antrian)</span>
                  )}
                </h3>
                <PaginationProvider pagination={paginationFactory(options)}>
                  {contentTable}
                </PaginationProvider>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="sweet-loading">
          <BounceLoader
            css={override}
            size={50}
            color={'#1289D1'}
            loading={this.state.loading}
          />
        </div>
      );
    }
  }
}
export default Queues;
