import React, { Component } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, Form, Button, Alert } from 'react-bootstrap';
import swal from 'sweetalert';
import Moment from 'moment';
import 'moment/locale/id';
import DatePicker, { registerLocale } from 'react-datepicker';
import id from 'date-fns/locale/id';
import LoadingOverlay from 'react-loading-overlay';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import { API_URL } from '../../../BaseUrlApi/Url';
import { RadioButton } from '../../../CustomJS/RadioButton';
import Radio from '../../../CustomJS/Radio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
// import Lightbox from 'react-spring-lightbox';
// import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from 'simple-react-lightbox';
import WhatsappLogo from '../../../../assets/whatsapp.svg';
import './DetailQueueConsult.css';
import moment from 'moment';
registerLocale('id', id);

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

class DetailQueueConsult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      queueId: null,
      date: '',
      order: null,
      fullname: '',
      age: null,
      gender: '',
      mother: '',
      father: '',
      phone_number_link: '',
      phone_number: null,
      statuses: [],
      paymentStatuses: [],
      status: {},
      payments: {},
      childId: null,
      queue_status: {},
      registration_origin: {},
      registration_origin_id: null,
      payment_confirmation: null,
      payment_status: null,
      isShow: false,
      valueOther: '',
      radioValue: null,
      selectedRefused: null,
      destination_bank: null,
      paymentRefuseds: [],
      date_change: null,
      isShowDate: false,
      excludeDate: [],
    };
  }

  handleChangeOther = (other) => {
    this.setState({ valueOther: other });
  };

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    this.setState({ isLoaded: true });
    let queueId = parseInt(this.props.match.params.value);
    let getQueue = await axios.get(`${API_URL}/admin/queues/${queueId}`, {
      headers,
    });
    let getQueueStatuses = await axios.get(`${API_URL}/admin/queue_statuses`, {
      headers,
    });
    let getPaymentRefused = await axios.get(
      `${API_URL}/admin/payment_rejection_reasons`,
      {
        headers,
      }
    );
    let queueStatuses = await getQueueStatuses.data.queueStatuses
      .filter((x) => ![3, 8].includes(x.id))
      .map((status) => ({
        value: status.id,
        label: status.name,
      }));

    let filterPaymentStatuses = [
      {
        value: 1,
        label: 'Belum Bayar',
      },
      {
        value: 2,
        label: 'Menunggu Konfirmasi',
      },
      {
        value: 3,
        label: 'Sudah Bayar',
      },
    ];

    const { data: dataDates } = await axios.get(
      `${API_URL}/admin/blocked_dates`,
      {
        headers,
      }
    );

    const { data: dataDatesKonsulOnline } = await axios.get(
      `${API_URL}/blocked_dates?shift_type=konsultasi_online`,

      {
        headers,
      }
    );

    let filterBlockedDatesKonsultasiOnline =
      await dataDatesKonsulOnline.blockedDates.map((item) =>
        Date.parse(new Date(item.date).toUTCString())
      );

    //parse date to format string long to ISO
    let filterBlockedDates = await dataDates.blockedDates.map((item) =>
      Date.parse(new Date(item.date).toUTCString())
    );
    // console.log('blocked-date', dataDates.blockedDates.map(item => (
    //   item.date
    // )));
    // console.log(filterBlockedDates)

    this.setState({
      queueId: queueId,
      date: getQueue.data.queue_info.date,
      order: getQueue.data.queue_info.registration_number,
      childId: getQueue.data.queue_info.child.id,
      fullname: getQueue.data.queue_info.child.fullname,
      age: getQueue.data.queue_info.child.age,
      gender: getQueue.data.queue_info.child.gender,
      address: getQueue.data.queue_info.child.parents.address,
      mother: getQueue.data.queue_info.child.parents.mother_name,
      father: getQueue.data.queue_info.child.parents.father_name,
      destination_bank: getQueue.data.queue_info.destination_bank_account,
      registration_origin: getQueue.data.queue_info.registration_origin,
      registration_origin_id: getQueue.data.queue_info.registration_origin_id,
      phone_number_link:
        getQueue.data.queue_info.phone_number.toString().charAt(0) === '0'
          ? `https://wa.me/62${parseInt(
              getQueue.data.queue_info.phone_number,
              10
            )}`
          : `https://wa.me/${parseInt(
              getQueue.data.queue_info.phone_number,
              10
            )}`,
      phone_number: getQueue.data.queue_info.phone_number,
      queue_status: getQueue.data.queue_info.queue_status,
      statuses: queueStatuses,
      paymentStatuses: filterPaymentStatuses,
      payment_confirmation: getQueue.data.queue_info.payment_confirmation,
      payment_status: getQueue.data.queue_info.payment_status,
      paymentRefuseds: getPaymentRefused.data.paymentRejectionReasons,
      excludeDate: [
        ...filterBlockedDatesKonsultasiOnline,
        ...filterBlockedDates,
      ],
    });
    await this.setState({
      status: {
        value: this.state.queue_status.id,
        label: this.state.queue_status.name,
      },
      // date: set_date,
    });
    await this.setState({
      payments: {
        value: this.state.payment_status.id,
        label: this.state.payment_status.name,
      },
      // date: set_date,
    });
    this.setState({ isLoaded: false });
  }

  queueStatusHandler = async (value) => {
    this.setState({
      status: {
        value: value.value,
        label: value.label,
      },
    });
  };

  queuePaymentHandler = async (value) => {
    this.setState({
      payments: {
        value: value.value,
        label: value.label,
      },
    });
  };

  queueStatusPayment = (id) => {
    console.log(id);
    let formData = {
      _method: 'PATCH',
      payment_status_id: id,
      payment_rejection_reason_id: this.state.selectedRefused,
      additional_reason:
        this.state.valueOther === '' ? null : this.state.valueOther,
    };

    function clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
    }
    clean(formData);

    swal({
      title: `Ingin Mengubah Status Pembayaran Pasien ke 
      ${
        id === 3
          ? 'Sudah Bayar?'
          : id === 4
          ? 'Pembayaran Ditolak?'
          : 'Pembayaran dikembalikan?'
      }`,
      icon: 'warning',
      buttons: {
        cancel: 'Batal!',
        catch: {
          text: 'Ya, Ubah',
          value: 'continue',
        },
      },
      dangerMode: true,
    }).then(async (move) => {
      try {
        switch (move) {
          case 'continue':
            this.setState({ isShow: false });
            this.setState({ isLoaded: true });
            const { data } = await axios.post(
              `${API_URL}/admin/queues/${this.state.queueId}`,
              formData,
              { headers }
            );
            this.setState({ isLoaded: false });
            swal({
              title: 'Berhasil mengubah Status Pembayaran Pasien',
              // text: "Dokter Tidak Di Klinik",
              icon: 'success',
              button: 'OK',
            }).then((move) => {
              if (move) {
                this.props.history.push(`/queue-consult`);
              } else {
                return;
              }
            });
            break;

          default:
            this.setState({ isLoaded: false });
            break;
        }
      } catch (e) {
        console.log(e);
        console.log('error', e.response.data.message);
        this.setState({ isLoaded: false });
        swal({
          title: e.response.data.message,
          // text: "Dokter Tidak Di Klinik",
          icon: 'warning',
          type: 'error',
          button: 'OK',
        });
      }
    });
  };

  queueUpdatePhone = () => {
    let formData = {
      _method: 'PATCH',
      date: moment(this.state.date_change).format('YYYY-MM-DD'),
    };
    swal({
      title: `Ingin Mengubah Tanggal Antrian Pasien?`,
      icon: 'warning',
      buttons: {
        cancel: 'Batal!',
        catch: {
          text: 'Ya, Ubah',
          value: 'continue',
        },
      },
      dangerMode: true,
    }).then(async (move) => {
      try {
        switch (move) {
          case 'continue':
            this.setState({ isShowDate: false });
            this.setState({ isLoaded: true });
            const { data } = await axios.post(
              `${API_URL}/admin/queues/${this.state.queueId}`,
              formData,
              { headers }
            );
            this.setState({ isLoaded: false });
            swal({
              title: 'Berhasil mengubah Tanggal Antrian Pasien',
              // text: "Dokter Tidak Di Klinik",
              icon: 'success',
              button: 'OK',
            }).then((move) => {
              if (move) {
                this.props.history.push(`/queue-consult`);
              } else {
                return;
              }
            });
            break;

          default:
            this.setState({ isLoaded: false });
            break;
        }
      } catch (e) {
        console.log(e);
        console.log('error', e.response.data.message);
        this.setState({ isLoaded: false });
        swal({
          title: e.response.data.message,
          // text: "Dokter Tidak Di Klinik",
          icon: 'warning',
          type: 'error',
          button: 'OK',
        });
      }
    });
  };

  queueUpdateStatus = async () => {
    let update_status = {
      _method: 'PATCH',
      queue_status_id: this.state.status.value,
      payment_status_id: this.state.payments.value,
    };
    this.setState({ isLoaded: true });

    axios
      .post(`${API_URL}/admin/queues/${this.state.queueId}`, update_status, {
        headers,
      })
      .then((res) => {
        this.setState({ isLoaded: false });
        swal({
          title: 'Sukses!',
          text: 'Data Antrian Telah Berhasil Diubah!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/queue-consult`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Lengkapi semua data terlebih dahulu',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };
  render() {
    let {
      isLoaded,
      date,
      order,
      fullname,
      age,
      gender,
      address,
      father,
      mother,
      phone_number,
      phone_number_link,
      queue_status,
      statuses,
      status,
      paymentStatuses,
      payments,
      payment_confirmation,
      payment_status,
      isShow,
      valueOther,
      selectedRefused,
      paymentRefuseds,
      destination_bank,
      radioValue,
      date_change,
      excludeDate,
      isShowDate,
      registration_origin,
      registration_origin_id,
    } = this.state;
    console.log(this.props);

    const options = {
      settings: {
        overlayColor: 'rgba(43, 45, 66, 0.95)',
        transitionTimingFunction: 'ease-in-out',
        slideTransitionSpeed: 0.8,
        slideTransitionTimingFunction: [0.25, 0.75, 0.5, 1],
        slideAnimationType: 'slide',
        disablePanzoom: false,
        disableWheelControls: true,
        hideControlsAfter: true,
      },
      buttons: {
        showDownloadButton: false,
        showAutoplayButton: false,
        showNextButton: false,
        showPrevButton: false,
      },
      thumbnails: {
        showThumbnails: false,
      },
    };

    const isWeekday = (date) => {
      const day = date.getDay();
      return day !== 0;
    };

    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Detail Antrian Online</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: '#039BEB' }}>Manajemen Antrian </span>
                <Link to="/queue-consult">
                  <span style={{ color: '#039BEB' }}>
                    / Daftar Antrian Online
                  </span>
                </Link>
                <span style={{ color: '#00000029' }}>
                  {' '}
                  / Detail Antrian Online
                </span>
              </h2>
            </div>
            <div className="card shadow mb-5 text-left pl-3">
              <div className="card-body">
                {queue_status !== null ? (
                  queue_status.id === 7 && payment_status.id === 3 ? (
                    <Alert
                      variant="danger"
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto auto',
                        gap: '0.5em',
                      }}
                    >
                      <div>
                        <h5>Pembayaran tiket Belum dikembalikan</h5>
                        <p>
                          Pengembalian pembayaran harus dilakukan paling lama 2
                          x 24 jam
                        </p>
                      </div>
                      <div style={{ placeSelf: 'center' }}>
                        <Button
                          variant="primary"
                          block
                          // disabled={radioValue === ''}

                          onClick={() => this.queueStatusPayment(5)}
                        >
                          Konfirmasi Pengembalian Pembayaran
                        </Button>
                      </div>
                    </Alert>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    gap: '0.5em',
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignContent: 'space-between',
                        flexDirection: 'row',
                      }}
                    >
                      <div style={{ margin: '0 0.5em', alignSelf: 'center' }}>
                        <h5 style={{ margin: '0' }}>Status Tiket </h5>
                      </div>

                      {queue_status !== null ? (
                        queue_status.id === 5 ? (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid rgb(63, 188, 7)',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: 'rgb(63, 188, 7)',
                              }}
                            >
                              {queue_status.name}
                            </p>
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                      {queue_status !== null ? (
                        queue_status.id === 1 ||
                        queue_status.id === 2 ||
                        queue_status.id === 4 ||
                        queue_status.id === 6 ? (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid #FA9000',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: '#FA9000',
                              }}
                            >
                              {queue_status.name}
                            </p>
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                      {queue_status !== null ? (
                        queue_status.id === 7 ? (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid red',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: 'red',
                              }}
                            >
                              {queue_status.name}
                            </p>
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    <h5>Anak</h5>
                    <table cellPadding="10" className="parent-data">
                      <tr>
                        <td>Nama</td>
                        <td>: {fullname}</td>
                      </tr>
                      <tr>
                        <td>Umur</td>
                        <td>: {age !== null ? age : '-'} tahun</td>
                      </tr>
                      <tr>
                        <td>Jenis Kelamin</td>
                        <td>
                          : {gender === 'male' ? 'Laki-laki' : 'Perempuan'}
                        </td>
                      </tr>
                    </table>
                    <br />
                    <h5>Orang Tua</h5>
                    <table cellPadding="10" className="parent-data">
                      <tr>
                        <td>Nama Ayah</td>
                        <td>: {father !== null ? father : '-'}</td>
                      </tr>
                      <tr>
                        <td>Nama Ibu</td>
                        <td>: {mother !== null ? mother : '-'}</td>
                      </tr>
                      <tr>
                        <td>Alamat</td>
                        <td>: {address !== null ? address : '-'}</td>
                      </tr>
                      <tr>
                        <td>No. Telp </td>
                        <td>: {phone_number}</td>
                      </tr>
                      {payment_status !== null
                        ? payment_status.id === 3 && (
                            <tr>
                              <td>
                                <a href={phone_number_link} target="_blank">
                                  <Button>
                                    <img
                                      src={WhatsappLogo}
                                      alt="whatsapp_ortu"
                                      style={{ width: '20px' }}
                                    />{' '}
                                    Mulai Konsultasi
                                  </Button>
                                </a>
                              </td>
                            </tr>
                          )
                        : ''}
                    </table>
                  </div>
                  <div>
                    <h5>Waktu</h5>
                    <table cellPadding="10" className="parent-data">
                      <tbody>
                        <tr>
                          <td>Tanggal Antri</td>
                          <td>
                            :{' '}
                            {Moment(date, 'YYYY-MM-DD').format(
                              'dddd, DD MMMM YYYY'
                            )}{' '}
                            {payment_status !== null ? (
                              payment_status.id === 3 ? (
                                <span style={{}}>
                                  <Button
                                    onClick={() =>
                                      this.setState({ isShowDate: true })
                                    }
                                    size="sm"
                                    variant="link"
                                  >
                                    <FontAwesomeIcon
                                      icon={faPencilAlt}
                                      className="mr-2"
                                    />
                                    Ganti Tanggal
                                  </Button>
                                </span>
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>No. Antrian</td>
                          <td>: {order}</td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        alignContent: 'space-between',
                        flexDirection: 'row',
                      }}
                    >
                      <div style={{ margin: '0 0.5em', alignSelf: 'center' }}>
                        <h5 style={{ margin: '0' }}>Detail Pembayaran </h5>
                      </div>

                      {payment_status !== null ? (
                        payment_status.id === 2 ? (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid #039beb',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: '#039beb',
                              }}
                            >
                              {payment_status.name}
                            </p>
                          </div>
                        ) : payment_status.id === 3 ? (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid #3FBC07',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: '#3FBC07',
                              }}
                            >
                              {payment_status.name}
                            </p>
                          </div>
                        ) : (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid red',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: 'red',
                              }}
                            >
                              {payment_status.name}
                            </p>
                          </div>
                        )
                      ) : (
                        <div
                          style={{
                            margin: '0 0.5em',
                            alignSelf: 'center',
                            border: '1px solid red',
                            borderRadius: '5px',
                          }}
                        >
                          <p
                            style={{
                              margin: '0',
                              padding: '.5em',
                              color: 'red',
                            }}
                          >
                            Belum Bayar
                          </p>
                        </div>
                      )}
                    </div>
                    <table cellPadding="10" className="parent-data">
                      <tbody>
                        {payment_status !== null ? (
                          payment_status.id === 4 ? (
                            <tr>
                              <td>Alasan Ditolak</td>
                              <td>
                                :{' '}
                                {payment_confirmation.rejection_reason.id !== 5
                                  ? payment_confirmation.rejection_reason.name
                                  : payment_confirmation.additional_reason}
                              </td>
                            </tr>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                        {registration_origin_id === 2 ? (
                          <tr>
                            <td>Pendaftaran & Pembayaran Via</td>
                            <td>: {registration_origin.name}</td>
                          </tr>
                        ) : (
                          ''
                        )}
                        {registration_origin_id === 3 ? (
                          <tr>
                            <td>Pendaftaran & Pembayaran Via</td>
                            <td>: {registration_origin.name}</td>
                          </tr>
                        ) : (
                          ''
                        )}
                        {registration_origin_id === 4 ? (
                          <tr>
                            <td>Pendaftaran & Pembayaran Via</td>
                            <td>: {registration_origin.name}</td>
                          </tr>
                        ) : (
                          ''
                        )}
                        {registration_origin_id === 5 ? (
                          <tr>
                            <td>Pendaftaran & Pembayaran Via</td>
                            <td>: {registration_origin.name}</td>
                          </tr>
                        ) : (
                          ''
                        )}

                        <tr>
                          <td>Rekening Pengirim</td>
                          <td>
                            :{' '}
                            {payment_confirmation !== null
                              ? payment_confirmation.sender_bank.name +
                                ' - Atas nama ' +
                                payment_confirmation.sender_account_name
                              : 'n/a'}
                          </td>
                        </tr>
                        <tr>
                          <td>Rekening Tujuan</td>
                          <td>
                            :{' '}
                            {destination_bank !== null
                              ? destination_bank.bank.name +
                                ' - Atas nama ' +
                                destination_bank.name
                              : 'n/a'}
                          </td>
                        </tr>
                        <tr>
                          <td>Jumlah</td>
                          <td>
                            :{' '}
                            {payment_confirmation !== null
                              ? `Rp ${payment_confirmation.idr_amount.toLocaleString(
                                  'id-ID'
                                )}`
                              : 'n/a'}
                          </td>
                        </tr>
                        {payment_confirmation === null ? (
                          <tr>
                            <td>Bukti Pembayaran</td>
                            <td>: n/a</td>
                          </tr>
                        ) : (
                          ''
                        )}
                      </tbody>
                    </table>
                    {payment_confirmation !== null ? (
                      <>
                        <p>Bukti Pembayaran: </p>
                        <SRLWrapper options={options}>
                          <div className="container-wrapper-receipt">
                            <img
                              className="image-payment"
                              src={payment_confirmation.receipt_image_url}
                              alt="Receipt-patient"
                            />
                            <div className="middle-receipt">
                              {/* <a href={payment_confirmation.receipt_image_url} data-attribute="SRL"> */}
                              <p>Klik untuk Lihat</p>
                              {/* </a> */}
                            </div>
                          </div>
                        </SRLWrapper>
                        <div style={{ marginTop: '.5em' }}>
                          {payment_status.id === 2 ? (
                            <>
                              <button
                                className="btn btn-success"
                                onClick={() => this.setState({ isShow: true })}
                                // onClick={() => this.queueStatusPayment(5)}
                              >
                                Konfirmasi Pembayaran
                              </button>{' '}
                              {/* <button className="btn btn-danger mr-1" 
                          onClick={() => this.queueStatusPayment(4)}>
                            Tolak Pembayaran
                          </button> */}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <br />
                <br />
                <hr className="horizontal-line" />
                <br />
                <div className="input-detail-queue">
                  <div style={{ display: 'flex', gap: '1em' }}>
                    <div>
                      <label htmlFor="" className="detail-queue">
                        Status Antrian :
                      </label>
                      <Select
                        options={statuses}
                        value={status}
                        onChange={(value) => this.queueStatusHandler(value)}
                        className="select-selector"
                      />
                    </div>
                    <div>
                      <label htmlFor="" className="detail-queue">
                        Status Pembayaran :
                      </label>
                      <Select
                        options={paymentStatuses}
                        value={payments}
                        onChange={(values) => this.queuePaymentHandler(values)}
                        // className="select-selector"
                      />
                    </div>
                  </div>
                  <div className="patient-buttons">
                    <button
                      className="btn btn-primary"
                      onClick={this.queueUpdateStatus}
                    >
                      Simpan
                    </button>
                    <Link to={`/queue-consult`}>
                      <button className="btn btn-danger mr-1">Batal</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              style={{ zIndex: 1100 }}
              size="sm"
              show={isShowDate}
              onHide={() => this.setState({ isShowDate: false })}
              // centered
            >
              <Modal.Body>
                <h4 style={{ textAlign: 'center' }}>
                  Ganti Tanggal Konsultasi
                </h4>
                <Form className="form-payment-confirm">
                  <p>Tanggal Konsultasi Sekarang</p>
                  <small>
                    {Moment(date, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY')}
                  </small>
                  <hr />
                  <p>Tanggal Konsultasi Baru</p>
                  <DatePicker
                    selected={date_change}
                    locale="id"
                    className="date-picker-custom"
                    onChange={(e) => {
                      console.log(e);
                      this.setState({ date_change: e });
                    }}
                    excludeDates={excludeDate}
                    filterDate={isWeekday}
                    minDate={new Date()}
                    // locale="id-ID"
                    dateFormat="EEEE, dd MMMM yyyy"
                    placeholderText="Pilih Tanggal"
                    // required
                  />
                  <Button
                    variant="primary"
                    block
                    disabled={date_change === null}
                    onClick={() => this.queueUpdatePhone()}
                  >
                    Ubah Tanggal
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
            <Modal
              style={{ zIndex: 1100 }}
              size="sm"
              show={isShow}
              onHide={() => this.setState({ isShow: false })}
              // centered
            >
              <Modal.Body>
                <h4 style={{ textAlign: 'center' }}>
                  Konfirmasi Status Pembayaran
                </h4>
                <Form className="form-payment-confirm">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '5em',
                    }}
                  >
                    {[3, 4].map((num) => {
                      return (
                        <RadioButton
                          key={num}
                          value={num}
                          text={num === 3 ? 'Diterima' : 'Ditolak'}
                          selected={radioValue}
                          onChange={() => {
                            if (num === 3) {
                              this.setState({ valueOther: '' });
                              this.setState({ selectedRefused: null });
                            }
                            console.log(num);
                            this.setState({ radioValue: num });
                          }}
                        />
                      );
                    })}
                  </div>
                  <div
                    style={{ padding: '0em 3em', marginTop: '1em' }}
                    className={`choice-refused-payment ${
                      radioValue === 4 && 'show-choice-refused'
                    }`}
                  >
                    <p>
                      <b>Keterangan Penolakan</b>
                    </p>
                    {paymentRefuseds.map((item, i) => (
                      <Radio
                        key={i}
                        value={item.id}
                        text={item.name}
                        // value={this.props.inputValue}
                        valueOther={valueOther}
                        handleChangeOther={this.handleChangeOther}
                        selected={selectedRefused}
                        onChange={() => {
                          this.setState({ selectedRefused: item.id });
                        }}
                      />
                    ))}
                  </div>

                  <Button
                    variant="primary"
                    block
                    disabled={radioValue === ''}
                    onClick={() => this.queueStatusPayment(radioValue)}
                  >
                    Kirim Konfirmasi
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}
export default DetailQueueConsult;
