import React, { useEffect } from 'react';
import './SuccessCheckin.css';

import useCountdown from '@bradgarropy/use-countdown';
import ICSuccessCheckin from '../../../../../assets/ic-success.png';

export const ModalSuccessCheckin = ({ data, isOpen, onClose }) => {
  const countdown = useCountdown({
    seconds: 15,
    format: 'ss',
    onCompleted: onClose,
  });

  useEffect(() => {
    if (!isOpen) {
      countdown.pause();
    } else {
      countdown.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="container-success-checkin"
      style={{ display: isOpen ? 'flex' : 'none' }}
    >
      <div className="wrapper-success-checkin">
        <div className="top-content-success-checkin">
          <div className="img-wrapper-success-checkin">
            <img src={ICSuccessCheckin} alt="ic-timeover.png" />
          </div>

          <p className="title-success-checkin">Check In Berhasil</p>

          <div className="d-flex align-items-center justify-content-between">
            <p className="sub-title-success-checkin">Nama Pasien</p>
            <p className="sub-title-success-checkin">
              {data?.child?.fullname || '-'}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p className="sub-title-success-checkin">Nama Orang Tua</p>
            <p className="sub-title-success-checkin">
              {data?.child?.parents?.father_name || '-'}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p className="sub-title-success-checkin">Nomor Pesanan</p>
            <p className="sub-title-success-checkin">
              {data?.registration_number || '-'}
            </p>
          </div>

          <div className="wrapper-queue-success-checkin">
            <p className="sub-title-queue-success-checkin">Nomor Antrian</p>
            <p className="queue-number-success-checkin">
              {data?.queue_number || '-'}
            </p>
            {/* <p className="sub-title-queue-success-checkin">
              tersisa <span style={{ fontWeight: 'bold' }}>6 Antrian</span>
            </p> */}
          </div>
        </div>
        <div className="">
          <button
            type="button"
            class="btn btn-primary button-success-checkin"
            onClick={onClose}
          >
            Ke Halaman Utama ({countdown.formatted})
          </button>
        </div>
      </div>
    </div>
  );
};
