import React, { Component } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import productsGenerator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import { API_URL } from '../../../BaseUrlApi/Url';
import './DetailPatient.css';
import { Alert, Button, Modal, Space, Typography, message } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import Spacer from '../../../Spacer';
import ICRemoveDuplicate from '../../../../assets/ic-remove-duplicate.svg';
import { TableDuplicateData } from '../../DuplicateData/components/TableDuplicateData/TableDuplicateData';
import { onDeleteDuplicate } from '../../DuplicateData/services/postDeleteDataDuplicate';

const { SearchBar } = Search;
const { Title } = Typography;

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

function dateFormatter(cell, row, rowIndex, formatExtraData) {
  if (cell !== null) {
    let date = new Date(cell);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_date = dd + '-' + mm + '-' + yyyy;
    return set_date;
  } else {
    return '-';
  }
}

function detailPatientFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="detail-buttons">
      <button
        onClick={() => {
          swal({
            title: 'Yakin Ingin Menghapus?',
            icon: 'warning',
            buttons: ['Batal', 'Hapus'],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              let parentId = row.parent_id;
              axios
                .delete(`${API_URL}/admin/children/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = `/detail-patient/${parentId}`;
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger patient-delete"
      >
        Hapus
      </button>
      <Link to={`/change-patient-data/${row.id}`}>
        <button className="btn btn-primary patient-change">Ubah</button>
      </Link>
      <Link to={`/checkup-history/${row.id}`}>
        <button className="btn btn-info patient-checkup">
          Riwayat Pemeriksaan
        </button>
      </Link>
      <Link to={`/upcoming-schedule/${row.id}`}>
        <button className="btn btn-info patient-checkup">
          Jadwal Mendatang
        </button>
      </Link>
    </div>
  );
}

function genderFormatter(cell, row, rowIndex, formatExtraData) {
  if (row.gender === 'female') {
    return <p>Perempuan</p>;
  } else {
    return <p>Laki-laki</p>;
  }
}
class DetailPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      parent_id: null,
      email: '',
      father_name: '',
      mother_name: '',
      father_job: null,
      father_job_id: null,
      mother_job: null,
      mother_job_id: null,
      phone_number: null,
      mother_phone_number: null,
      address: null,
      district: null,
      district_id: null,
      city: null,
      city_id: null,
      province: null,
      province_id: null,
      products: [],
      selectedDataDetail: [],
      columns: [
        {
          dataField: 'fullname',
          text: 'Nama',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
        },
        {
          dataField: 'gender',
          text: 'Jenis Kelamin',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: genderFormatter,
        },
        {
          dataField: 'birthdate',
          text: 'Tanggal Lahir',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: dateFormatter,
        },
        {
          dataField: 'birthplace',
          text: 'Tempat Lahir',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: (cell) => {
            if (cell !== null) {
              return cell;
            } else {
              return '-';
            }
          },
        },

        {
          dataField: 'Aksi',
          text: 'Aksi',
          headerStyle: { textAlign: 'center' },
          style: { margin: 'auto' },
          formatter: detailPatientFormatter,
        },
      ],
    };
  }

  loadData = () => {
    this.setState({
      products: productsGenerator(17),
    });
  };

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let parentId = this.props.match.params.value;
    let getDetailParent = await axios.get(
      `${API_URL}/admin/parents/${parentId}`,
      { headers }
    );

    const tempDataOriginal = [
      {
        father_name: getDetailParent?.data?.parent?.father_name,
        mother_name: getDetailParent?.data?.parent?.mother_name,
        children_count: getDetailParent?.data?.parent?.children_count,
        last_visit_date: getDetailParent?.data?.parent?.last_visit_date,
        is_registered_on_app:
          getDetailParent?.data?.parent?.is_registered_on_app,
        duplicate_data_count:
          getDetailParent?.data?.parent?.duplicate_data_count,
        no: 1,
        key: 1,
        id: getDetailParent?.data?.parent?.id,
      },
    ];

    if (getDetailParent.data.parent.user !== null) {
      this.setState({
        email: getDetailParent.data.parent.user.email,
        duplicate_data_count: getDetailParent.data.parent.duplicate_data_count,
        duplicate_data: getDetailParent.data.parent.duplicate_data,
        data_original: tempDataOriginal,
        father_name: getDetailParent.data.parent.father_name,
        mother_name: getDetailParent.data.parent.mother_name,
        father_job_id: getDetailParent.data.parent.father_job_id,
        mother_job_id: getDetailParent.data.parent.mother_job_id,
        phone_number: getDetailParent.data.parent.phone_number,
        mother_phone_number: getDetailParent.data.parent.mother_phone_number,
        address: getDetailParent.data.parent.address,
        district_id: getDetailParent.data.parent.district_id,
        city_id: getDetailParent.data.parent.city_id,
        province_id: getDetailParent.data.parent.province_id,
        products: getDetailParent.data.parent.children,
        parent_id: getDetailParent.data.parent.id,
      });
    } else {
      this.setState({
        email: 'Belum Terdaftar',
        data_original: tempDataOriginal,
        duplicate_data_count: getDetailParent.data.parent.duplicate_data_count,
        duplicate_data: getDetailParent.data.parent.duplicate_data,
        father_name: getDetailParent.data.parent.father_name,
        mother_name: getDetailParent.data.parent.mother_name,
        father_job_id: getDetailParent.data.parent.father_job_id,
        mother_job_id: getDetailParent.data.parent.mother_job_id,
        phone_number: getDetailParent.data.parent.phone_number,
        mother_phone_number: getDetailParent.data.parent.mother_phone_number,
        address: getDetailParent.data.parent.address,
        district_id: getDetailParent.data.parent.district_id,
        city_id: getDetailParent.data.parent.city_id,
        province_id: getDetailParent.data.parent.province_id,
        products: getDetailParent.data.parent.children,
        parent_id: getDetailParent.data.parent.id,
      });
    }

    if (this.state.father_job_id !== null) {
      let fatherJob = await axios.get(
        `${API_URL}/jobs/${this.state.father_job_id}`,
        { headers }
      );
      this.setState({ father_job: fatherJob.data.job.name });
    }

    if (this.state.mother_job_id !== null) {
      let motherJob = await axios.get(
        `${API_URL}/jobs/${this.state.mother_job_id}`,
        { headers }
      );
      this.setState({ mother_job: motherJob.data.job.name });
    }

    if (this.state.district_id !== null) {
      let getDistrict = await axios.get(
        `${API_URL}/regions/districts?id=${this.state.district_id}`
      );
      this.setState({ district: getDistrict?.data?.districts?.name || '-' });
    }

    if (this.state.city_id !== null) {
      let getCity = await axios.get(
        `${API_URL}/regions/cities?id=${this.state.city_id}`
      );
      this.setState({ city: getCity?.data?.cities?.name || '-' });
    }

    if (this.state.province_id !== null) {
      let getProvince = await axios.get(
        `${API_URL}/regions/provinces?id=${this.state.province_id}`
      );
      this.setState({ province: getProvince?.data?.provinces?.name || '-' });
    }

    this.setState({ isLoaded: false });
  }

  render() {
    let {
      email,
      father_name,
      mother_name,
      father_job,
      mother_job,
      phone_number,
      mother_phone_number,
      district,
      city,
      province,
      address,
      duplicate_data_count,
      duplicate_data,
      isShowModalDeleteDataDuplicate,
      selectedDataDetail = [],
      data_original,
    } = this.state;
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan {from} / {to} dari {size} Hasil
      </span>
    );
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '50',
          value: 50,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: 'All',
          value: this.state.products.length,
        },
      ],
    };
    const contentTable = ({ paginationTableProps }) => (
      <div className="text-left">
        <p className="family-members float-left mt-5">
          Anggota Keluarga {this.state.products.length > 0 ? '' : ''}
        </p>
        <ToolkitProvider
          keyField="id"
          columns={this.state.columns}
          data={this.state.products}
          search={{ searchFormatted: true }}
        >
          {(toolkitprops) => (
            <div>
              <SearchBar
                {...toolkitprops.searchProps}
                className="search-content"
              />
              <BootstrapTable
                bordered={false}
                {...toolkitprops.baseProps}
                {...paginationTableProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );

    const onDeleteDataDuplicate = (data) => {
      onDeleteDuplicate(data)
        .then(() => {
          message.success('Data duplikat berhasil dihapus !');
          this.setState({
            selectedDataDetail: [],
            isShowModalDeleteDataDuplicate: false,
          });
        })
        .catch(() => {
          message.error('Data duplikat gagal dihapus !');
        });
    };

    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section">
              <h1 className="patient-page-title">Detail Keluarga</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: '#039BEB' }}>Manajemen Pasien</span>
                <span style={{ color: '#039BEB' }}>
                  / Daftar Kepala Keluarga
                </span>
                <span style={{ color: '#00000029' }}>
                  / Detail Kepala Keluarga
                </span>
              </h2>
              <div className="add-button mr-3">
                <Link to={`/add-member/${this.state.parent_id}`}>
                  <button className="btn btn-primary btn-add-table mb-3">
                    Tambah Anggota Keluarga
                  </button>
                </Link>
              </div>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                {duplicate_data_count > 0 && (
                  <Alert
                    message="Terdapat kesamaan data dengan data keluarga lainnya"
                    type="warning"
                    style={{
                      borderRadius: 10,
                      textAlign: 'left',
                    }}
                    icon={<WarningFilled />}
                    showIcon
                    action={
                      <Button
                        size="small"
                        type="text"
                        onClick={() =>
                          this?.setState({
                            isShowModalDeleteDataDuplicate: true,
                          })
                        }
                        style={{
                          color: '#FFB400',
                        }}
                      >
                        Bandingkan Data
                      </Button>
                    }
                  />
                )}
                <Spacer height={10} />
                <table cellPadding="10" className="parent-data text-left mb-3">
                  <tbody>
                    <tr>
                      <td>Email</td>
                      <td>
                        :{' '}
                        {email !== null ? (
                          email
                        ) : (
                          <span className="text-danger">Belum Terdaftar</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Nama Ayah</td>
                      <td>: {father_name !== null ? father_name : '-'}</td>
                    </tr>
                    <tr>
                      <td>Nama Ibu</td>
                      <td>: {mother_name !== null ? mother_name : '-'}</td>
                    </tr>
                    <tr>
                      <td>Pekerjaan Ayah </td>
                      <td>: {father_job !== null ? father_job : '-'}</td>
                    </tr>
                    <tr>
                      <td>Pekerjaan Ibu </td>
                      <td>: {mother_job !== null ? mother_job : '-'}</td>
                    </tr>
                    <tr>
                      <td>No. Telp Ayah</td>
                      <td>: {phone_number !== null ? phone_number : '-'}</td>
                    </tr>
                    <tr>
                      <td>No. Telp Ibu</td>
                      <td>
                        :{' '}
                        {mother_phone_number !== null
                          ? mother_phone_number
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Alamat</td>
                      <td>
                        {' '}
                        : {address !== null ? address : ''}{' '}
                        {district !== null ? `, Kecamatan ${district}` : ''}{' '}
                        {city !== null ? `, ${city}` : ''}{' '}
                        {province !== null ? `, Provinsi ${province}` : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <PaginationProvider pagination={paginationFactory(options)}>
                  {contentTable}
                </PaginationProvider>
                <div className="patient-buttons">
                  <Link to="/patients">
                    <button className="btn btn-secondary mt-5 text-light">
                      Kembali
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>

        <Modal
          visible={isShowModalDeleteDataDuplicate}
          onOk={() => {}}
          zIndex={9999999}
          className="modal-duplicate-data-certificate"
          onCancel={() => {
            this.setState({
              isShowModalDeleteDataDuplicate: false,
              selectedDataDetail: [],
            });
          }}
          footer={null}
        >
          <div className="modal-duplicate-data-wrapper">
            <>
              <img src={ICRemoveDuplicate} alt="ic-remove-duplicate.svg" />
              <Spacer height={30} />
              <Title level={4}>Request Diterima</Title>
              <p className="modal-duplicate-data-description">
                Anda akan menghapus data duplikat user Klinik Pediatrica Hussada
                dengan detail sebagai berikut:
              </p>

              <Spacer height={16} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Title level={5}>Data Original</Title>
              </div>
              <div style={{ maxWidth: '100%' }}>
                <TableDuplicateData
                  dataSource={this.state.data_original || []}
                  isDetail={this.state.isShowModalDeleteDataDuplicate}
                  rowSelection={{
                    type: 'checkbox',
                    hideSelectAll: true,
                    selectedRowKeys: selectedDataDetail?.map(
                      (item) => item?.id
                    ),
                    onSelect: (record, selected) => {
                      if (selected) {
                        return this.setState({
                          selectedDataDetail: [
                            ...this?.state?.selectedDataDetail,
                            record,
                          ],
                        });
                      } else {
                        const tempData =
                          this?.state?.selectedDataDetail?.filter(
                            (item) => item?.id !== record?.id
                          );
                        return this.setState({
                          selectedDataDetail: tempData,
                        });
                      }
                    },
                  }}
                  // isLoading={isLoadingDetail}
                  // pagination={{
                  //   ...paginationTable,
                  // }}
                />
              </div>

              <Spacer height={16} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Title level={5}>Data Duplicate</Title>
              </div>
              <div style={{ maxWidth: '100%' }}>
                <TableDuplicateData
                  dataSource={this?.state?.duplicate_data || []}
                  isDetail={isShowModalDeleteDataDuplicate}
                  rowSelection={{
                    type: 'checkbox',
                    hideSelectAll: true,
                    selectedRowKeys: selectedDataDetail?.map(
                      (item) => item?.id
                    ),
                    onSelect: (record, selected) => {
                      if (selected) {
                        return this.setState({
                          selectedDataDetail: [
                            ...this.state.selectedDataDetail,
                            record,
                          ],
                        });
                      } else {
                        const tempData =
                          this?.state?.selectedDataDetail?.filter(
                            (item) => item?.id !== record?.id
                          );
                        return this.setState({
                          selectedDataDetail: tempData,
                        });
                      }
                    },
                  }}
                  // isLoading={isLoadingDetail}
                  // pagination={{
                  //   ...paginationTable,
                  // }}
                />
              </div>

              <Spacer height={34} />
              <div>
                <Space size="small">
                  <Button
                    onClick={() => {
                      this.setState({
                        isShowModalDeleteDataDuplicate: false,
                        selectedDataDetail: [],
                      });
                    }}
                  >
                    Batalkan
                  </Button>
                  <Button
                    type="primary"
                    danger
                    style={{
                      ...(!!this?.state?.selectedDataDetail?.length && {
                        background: '#CB444A',
                      }),
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    // disabled={!this?.state?.selectedDataDetail.length || isLoadingDeleteData}
                    // loading={isLoadingDeleteData}
                    onClick={() => onDeleteDataDuplicate(selectedDataDetail)}
                  >
                    Hapus Duplikasi
                  </Button>
                </Space>
              </div>
            </>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DetailPatient;
