import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import AdminSidebar from "../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../BaseUrlApi/Url";
import "./DetailActiveQueue.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class DetailActiveQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      childId: null,
      queueId: null,
      shiftId: null,
      child_name: "",
      birthdate: "",
      gender: null,
      father_name: "",
      mother_name: "",
      address: "",
      shift: "",
      date: "",
      note: null,
      nurse: null,
      period: null,
      vaccination: null,
      isOpen: false,
      isOpen2: false,
      vaccine_type: null,
      vaccine: null,
      vaccine_id: null,
      date_plan: null,
      date_taken: null,
      nurse_id: null,
      statuses: [],
      status: {},
      health_worker: null,
      is_free: null,
      mother_phone_number:null,
      father_phone_number:null
    };
  }
  async componentDidMount() {
    this.setState({ isLoaded: true });

    let queueId = parseInt(this.props.match.params.value);
    let shiftId = parseInt(this.props.match.params.shiftId);
    let getQueue = await axios.get(`${API_URL}/admin/queues/${queueId}`, {
      headers,
    });
    let getQueueStatuses = await axios.get(`${API_URL}/admin/queue_statuses`, {
      headers,
    });
    let queueStatuses = getQueueStatuses.data.queueStatuses.filter(item => ![9, 10, 11, 12].includes(item.id)).map((status) => ({
      value: status.id,
      label: status.name,
    }));
    await this.setState({
      childId: getQueue.data.queue_info.child_id,
      queueId: queueId,
      child_name: getQueue.data.queue_info.child.fullname,
      birthdate: getQueue.data.queue_info.child.birthdate,
      gender: getQueue.data.queue_info.child.gender,
      father_name: getQueue.data.queue_info.child.parents.father_name,
      father_phone_number: getQueue.data.queue_info.child.parents.phone_number,
      mother_name: getQueue.data.queue_info.child.parents.mother_name,
      mother_phone_number: getQueue.data.queue_info.child.parents.mother_phone_number,
      address: getQueue.data.queue_info.child.parents.address,
      health_worker: getQueue.data.queue_info.child.parents.is_health_worker,
      is_free: getQueue.data.queue_info.child.parents.is_free,
      shift:
        getQueue.data.queue_info.shift.name +
        " (" +
        getQueue.data.queue_info.shift.start_time +
        " - " +
        getQueue.data.queue_info.shift.end_time +
        ")",
      date: getQueue.data.queue_info.date,
      shiftId: shiftId,
      note: getQueue.data.queue_info.note,
      statuses: queueStatuses,
      status: {
        value: getQueue.data.queue_info.queue_status.id,
        label: getQueue.data.queue_info.queue_status.name,
      },
      vaccination:
        getQueue.data.queue_info.vaccinations.length > 0
          ? getQueue.data.queue_info.vaccinations
          : null,
    });

    let date = new Date(this.state.birthdate);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    let set_date = dd + "-" + mm + "-" + yyyy;
    this.setState({
      birthdate: set_date,
    });

    this.setState({ isLoaded: false });

    if (this.state.vaccination !== null) {
      let getVaccineTypeId = await axios.get(
        `${API_URL}/admin/vaccine_types/${this.state.vaccination[0].vaccine_type_id}`,
        { headers }
      );
      this.setState({ vaccine_type: getVaccineTypeId.data.vaccine_type.name });
      let getVaccine = await axios.get(
        `${API_URL}/admin/vaccines/${this.state.vaccination[0].vaccine_id}`,
        { headers }
      );
      this.setState({ vaccine: getVaccine.data.data.brand_name });
      let getNurse = await axios.get(
        `${API_URL}/admin/users/${this.state.vaccination[0].nurse_id}`,
        { headers }
      );
      await this.setState({ nurse: getNurse.data.user.name });
    }
  }
  showModal = async (e) => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  hideModal = async (e) => {
    this.setState({ isOpen: false });
  };

  showModal2 = async (e) => {
    this.setState({ isOpen2: !this.state.isOpen });
  };

  hideModal2 = async (e) => {
    this.setState({ isOpen2: false });
  };

  statusHandler = async (value) => {
    this.setState({
      status: {
        value: value.value,
        label: value.label,
      },
    });
  };

  dateConverter(value) {
    let date = new Date(value);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    let set_date = dd + "-" + mm + "-" + yyyy;
    return set_date;
  }

  updateStatus = async () => {
    let update_status = {
      _method: "PATCH",
      queue_status_id: this.state.status.value,
    };
    this.setState({isLoaded: true})

    axios
      .post(`${API_URL}/admin/queues/${this.state.queueId}`, update_status, {
        headers,
      })
      .then((res) => {
        this.setState({isLoaded: false})
        swal({
          title: "Sukses!",
          text: "Data Antrian Telah Berhasil Diubah!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/queues/${this.state.shiftId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        this.setState({isLoaded: false})
        swal({
          title: "GAGAL",
          text: "Status Gagal Diperbarui",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };
  render() {
    let {
      child_name,
      birthdate,
      gender,
      father_name,
      mother_name,
      address,
      shift,
      date,
      nurse,
      shiftId,
      status,
      vaccination,
      statuses,
      vaccine_type,
      health_worker,
      vaccine,
      mother_phone_number, 
      father_phone_number
    } = this.state;

    return (
      <div className="admin-overlay">
        <AdminSidebar />

        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="user-page-title">Detail Anak</h1>
              <h2 className="user-page-subtitle">
                <span style={{ color: "#039BEB" }}>Antrian</span>
                <span style={{ color: "#039BEB" }}>/ Daftar Antrian</span>
                <span style={{ color: "#00000029" }}> / Detail Anak</span>
              </h2>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="btn-worker">
                  {health_worker === 1 ? (
                    <button className="btn btn-info">
                      Orang Tua Pekerja Kesehatan
                    </button>
                  ) : (
                    ""
                  )}
                  {health_worker === 1 ? (
                    <button
                      className="btn btn-primary"
                      style={{ marginLeft: "1rem" }}
                    >
                      Bisa Digratiskan
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <table
                  cellPadding="10"
                  className="active-queue-table text-left ml-3"
                >
                  <tbody>
                    <p className="account-title">Anak</p>
                    <tr>
                      <td>
                        <label htmlFor="">Nama</label>
                      </td>
                      <td> : {child_name} </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">Tanggal Lahir</label>
                      </td>
                      <td> : {birthdate !== null ? birthdate : "-"}</td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">Jenis Kelamin</label>
                      </td>
                      <td>
                        
                        : {gender === "male" ? "Laki-laki" : "Perempuan"}
                      </td>
                    </tr>
                    <br />
                    <p className="account-title">Orang Tua</p>
                    <tr>
                      <td>
                        <label htmlFor="">Nama Ayah</label>
                      </td>
                      <td> : {father_name !== null ? father_name : "-"}</td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">Nama Ibu</label>
                      </td>
                      <td> : {mother_name !== null ? mother_name : "-"}</td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">Alamat</label>
                      </td>
                      <td> : {address !== null ? address : "-"}</td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">No.Telp Ayah</label>
                      </td>
                      <td> : {father_phone_number !== null ? father_phone_number : "-"}</td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">No.Telp Ibu</label>
                      </td>
                      <td> : {mother_phone_number !== null ? mother_phone_number : "-"}</td>
                    </tr>
                    <br />
                    <p className="account-title">Waktu</p>
                    <tr>
                      <td>
                        <label htmlFor="">Kunjungan</label>
                      </td>
                      <td> : {shift}</td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">Tanggal</label>
                      </td>
                      <td> : {this.dateConverter(date)}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <div className="input-detail-queue text-left ml-3">
                  <label htmlFor="" className="detail-queue">
                    Status Antrian :
                  </label>
                  <Select
                    options={statuses}
                    value={status}
                    onChange={(value) => this.statusHandler(value)}
                    className="select-selector"
                    // styles={customStyles}
                  />
                </div>
                <Modal show={this.state.isOpen} onHide={this.hideModal}>
                  <Modal.Header>
                    <Modal.Title>
                      <h1 className="note-child-title">Catatan Anak </h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="note-text">
                      {this.state.note !== null ? (
                        this.state.note
                      ) : (
                        <span style={{ color: "red" }}>Belum ada catatan</span>
                      )}
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn btn-primary note-button"
                      onClick={this.hideModal}
                    >
                      OK
                    </button>
                  </Modal.Footer>
                </Modal>
                <Modal show={this.state.isOpen2} onHide={this.hideModal2}>
                  <Modal.Header>
                    <Modal.Title>
                      <h1 className="note-child-title">Vaksinasi</h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <table cellPadding="10" className="vaccination-data mb-5">
                      <tbody>
                        <tr>
                          <td>
                            <label htmlFor="" className="vaccine-label">
                              Tipe Vaksin
                            </label>
                          </td>
                          <td>
                            
                            <p className="vaccine-text">
                              :
                              {vaccine_type !== null ? (
                                vaccine_type
                              ) : (
                                <span style={{ color: "red" }}>
                                  Belum ada data
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="" className="vaccine-label">
                              Merk Vaksin
                            </label>
                          </td>
                          <td>
                            
                            <p className="vaccine-text">
                              
                              :
                              {vaccine !== null ? (
                                vaccine
                              ) : (
                                <span style={{ color: "red" }}>
                                  Belum ada data
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="" className="vaccine-label">
                              Periode
                            </label>
                          </td>
                          <td>
                            
                            <p className="vaccine-text">
                              
                              :
                              {vaccination !== null ? (
                                vaccination[0].period
                              ) : (
                                <span style={{ color: "red" }}>
                                  Belum ada data
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="" className="vaccine-label">
                              Pemeriksa
                            </label>
                          </td>
                          <td>
                            
                            <p className="vaccine-text">
                              
                              :
                              {nurse !== null ? (
                                nurse
                              ) : (
                                <span style={{ color: "red" }}>
                                  Belum ada data
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn btn-primary note-button"
                      onClick={this.hideModal2}
                    >
                      OK
                    </button>
                  </Modal.Footer>
                </Modal>
                <div className="active-queue-buttons">
                  <Link to={`/queues/${shiftId}`}>
                    <button
                      className="btn btn-secondary btn-active-queue"
                      style={{ color: "white", float: "right" }}
                    >
                      Kembali
                    </button>
                  </Link>
                  <button
                    className="btn btn-primary btn-active-queue"
                    onClick={this.showModal2}
                  >
                    Vaksinasi
                  </button>
                  <button
                    className="btn btn-primary btn-active-queue"
                    onClick={this.showModal}
                  >
                    Catatan Anak
                  </button>
                  <button
                    className="btn btn-primary btn-active-queue"
                    onClick={this.updateStatus}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default DetailActiveQueue;
